import React, { useEffect, useState } from "react";
import store from "../store";
import useOrder from "../hooks/useOrder";

const Switchtable = ({ table,close ,handleClosePromptBYSwitch }) => {
  const {handleSwitchTable} = useOrder();
  const [processing, setProcessing] = useState([]);
  const [empty,setEmpty] = useState([]);

  useEffect(() => {
    if (table && table.length > 0) {
      // Gather all the data from table and update state once
      // const allTableData = table
      //   .filter((ele) => ele.roomName !== "Parcel")
      //   .map((ele) => ele.tableData);
      console.log(store.rooms,"...this is ");
      const data= store.rooms;
      console.log(store.printedKOT_VPA,"...");
      const filterTableVPAs= store.printedKOT_VPA;
      const KotOnProcess = data.flatMap((room) =>
        room.tableData
          .filter((table) =>
            filterTableVPAs.some((filter) => filter.tableVPA === table.qrpidVPA)
          )
          .map((table) => ({
            roomName: room.roomName,
            tableNo: table.tableNo,
            qrpidVPA: table.qrpidVPA,
          }))
      );

      const EmptyTable = data.flatMap((room) =>
        room.tableData
          .filter((table) =>
            filterTableVPAs.some((filter) => filter.tableVPA !== table.qrpidVPA)
          )
          .map((table) => ({
            roomName: room.roomName,
            tableNo: table.tableNo,
            qrpidVPA: table.qrpidVPA,
          }))
      );
      setEmpty(EmptyTable);
      setProcessing(KotOnProcess);
      console.log(KotOnProcess,"...",EmptyTable);
      
      
      
      // setAllltable(allTableData.flat());
    }
  }, [table]); // UseEffect will run whenever `table` changes

  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");
  const handleSwitch = async()=>{
   const status = await handleSwitchTable(fromValue,toValue);
  
    close();
    handleClosePromptBYSwitch();
   
  
  }

  // return (
   
  // );

  return (
    <div className="absolute top-3 left-1/2 transform -translate-x-1/2 bg-white shadow-lg rounded-lg p-6 ">
      <h3 className="text-lg font-bold mb-6 text-gray-700">
        Switch to a different table
      </h3>

      <div className="flex flex-row items-center gap-5">
      <div className="flex flex-col space-y-2">
        <label htmlFor="fromDropdown" className="text-lg font-medium">
          From:
        </label>
        <select
          id="fromDropdown"
          className="border border-gray-300 rounded-md p-2 w-64 focus:ring-2 focus:ring-blue-500"
          value={fromValue}
          onChange={(e) => setFromValue(e.target.value)}
        >
          <option value="" disabled>
            Select an option
          </option>
          {processing.map((item, index) => (
            <option key={index} value={item.qrpidVPA}>
              {item.roomName} - Table {item.tableNo}
            </option>
          ))}
        </select>
      </div>

      <div className="flex flex-col space-y-2">
        <label htmlFor="toDropdown" className="text-lg font-medium">
          To:
        </label>
        <select
          id="toDropdown"
          className="border border-gray-300 rounded-md p-2 w-64 focus:ring-2 focus:ring-blue-500"
          value={toValue}
          onChange={(e) => setToValue(e.target.value)}
        >
          <option value="" disabled>
            Select an option
          </option>
          {empty.map((item, index) => (
            <option key={index} value={item.qrpidVPA}>
              {item.roomName} - Table {item.tableNo}
            </option>
          ))}
        </select>
      
      </div>

    </div>
    <div className="mt-4">
        <p className="text-sm font-medium">
          <strong>Selected From:</strong> {fromValue || "None"}
        </p>
        <p className="text-sm font-medium">
          <strong>Selected To:</strong> {toValue || "None"}
        </p>
      </div>
      {/* Buttons Section */}
      <div className="flex justify-end mt-6 space-x-3">
        <button onClick={close} className="bg-gray-400 text-white py-2 px-4 rounded-md hover:bg-gray-500 transition duration-300">
          Cancel
        </button>
        <button onClick={handleSwitch} className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300">
          Confirm
        </button>
      </div>
    </div>
  );
};

export default Switchtable;
