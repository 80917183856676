import React, { useEffect, useState } from "react";
import { PrintBills } from "../../Print/PrintBill";
import { billGenerate, getbillGenerate } from "./Services/Payment.service";
import { paymentMethodAPI } from "../Service/KotStatusChange.service";
import useOrder from "../../../hooks/useOrder";
import toast, { Toaster } from "react-hot-toast";

const NameAndNoSection =({name,mobile,setName,setMobile})=>(
  < div className="flex justify-flex gap-0.5">
   <div>
            Name
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="block  w-4/5 p-2 border border-gray-300 rounded"
              placeholder="Name"
            />
          </div>
          <div>
          Mobile
            <input
              type="text"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              className="block   p-2 border border-gray-300 rounded"
              placeholder="Enter MoNo"
            />
          </div>
  </div>
)

const WaiterSelection = ({ selectedWaiter, setSelectedWaiter, waiterlist }) => (
  <>
  
    <h3 className="text-lg font-semibold mb-4">Select Waiter</h3>
    <select
      value={selectedWaiter}
      onChange={(e) => setSelectedWaiter(e.target.value)}
      className="block w-full mb-4 p-2 border border-gray-300 rounded"
    >
      <option value="">Select Waiter</option>
      {waiterlist.map((waiter) => (
        <option key={waiter.id} value={waiter.id}>
          {waiter.name}
        </option>
      ))}
    </select>
  </>
);

const DiscountSection = ({
  billPrinted,
  discountFlat,
  setDiscountFlat,
  discount,
  setDiscount,
  setExtraDiscounts,
  setExtraDiscount,
}) => (
  <>
    <h3 className="text-lg font-semibold mb-4">Discount</h3>
    <div className="flex justify-between">
      {!billPrinted && (
        <>
          <div>
            Flat Discount
            <input
              type="number"
              value={discountFlat}
              onChange={(e) => setDiscountFlat(e.target.value)}
              className="block w-3/4 mb-4 p-2 border border-gray-300 rounded"
              placeholder="Flat Discount"
            />
          </div>
          <div>
            Percentage Discount
            <input
              type="number"
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
              className="block w-3/4 mb-4 p-2 border border-gray-300 rounded"
              placeholder="Percentage Discount"
            />
          </div>
        </>
      )}
      {billPrinted && (
        <div className="flex justify-between">
          <div>Discount : Rs {parseInt(discountFlat)}</div>
          <div className="pl-8">
            Extra Discount
            <input
              type="number"
              value={setExtraDiscounts}
              onChange={(e) => setExtraDiscount(e.target.value)}
              className="block w-3/4 mb-4 p-2 border border-gray-300 rounded"
              placeholder="Percentage Discount"
            />
          </div>
        </div>
      )}
    </div>
  </>
);

const BillSummary = ({
  order,
  billPrinted,
  discount,
  discountFlat,
  setExtraDiscounts,
}) => (
  <div>
    {order[0].amount && !billPrinted && (
      <>
        <h2>Sub Amount : {(order[0].amount).toFixed(2)}</h2>
        <>
          <h1>
            Discount:{" "}
            {(order[0].amount * (discount || 0)) / 100 +
              parseInt(discountFlat || 0) +
              parseFloat(setExtraDiscounts || 0)}
          </h1>
          <h2>
            <strong>
              
              Total Amount :{" "}
              </strong>
            {parseFloat(
              parseFloat(order[0].amount).toFixed(2) -
                (order[0].amount * (discount || 0)) / 100 -
                parseInt(discountFlat || 0) -
                parseFloat(setExtraDiscounts || 0).toFixed(2)
            ).toFixed(2)}
          </h2>
        </>
      </>
    )}
  </div>
);

const TipsInput = ({ tips, setTips }) => (
  <>
    <h3 className="text-lg font-semibold mb-4">Tips for Waiter</h3>
    <input
      type="number"
      value={tips}
      onChange={(e) => setTips(e.target.value)}
      className="block w-full mb-4 p-2 border border-gray-300 rounded"
      placeholder="Enter Tips"
    />
  </>
);

const PaymentMethodSelection = ({
  paymentMethod,
  setPaymentMethod,
  setError,
}) => (
  <>
    <h3 className="text-lg font-semibold mb-4">Payment Method</h3>
    <select
      value={paymentMethod}
      onChange={(e) => {
        setPaymentMethod(e.target.value);
        setError("");
      }}
      className="block w-full mb-1 p-2 border border-gray-300 rounded"
    >
      <option value="">Select Payment Method</option>
      <option value="cash">Cash</option>
      <option value="online">Online</option>
      <option value="card">Card</option>
      <option value="partial">Partial</option>
      <option value="pending">Pending</option>
    </select>
  </>
);

const PartialPaymentInputs = ({ partialPayment, setPartialPayment }) => (
  <div className="partial-payment mb-4">
    {["cash", "online", "card"].map((method) => (
      <label key={method} className="block mb-2">
        {method.charAt(0).toUpperCase() + method.slice(1)}:
        <input
          type="number"
          name={method}
          value={partialPayment[method]}
          onChange={(e) =>
            setPartialPayment({
              ...partialPayment,
              [e.target.name]: e.target.value,
            })
          }
          className="block w-full p-2 border border-gray-300 rounded mt-1"
        />
      </label>
    ))}
  </div>
);

const Actions = ({
  billPrinted,
  handleGenerateBill,
  ReprinthandleGenerateBill,
  handlePaymentComplete,
  paymentbtn,
  order,
  setExtraDiscounts,
}) => (<>
{order[0].amount && billPrinted && (
      <h4 className="mt-5">
        <strong>
          Total Amount :- ₹
          {parseFloat(order[0].amount) - parseFloat(setExtraDiscounts || 0)}
        </strong>
      </h4>
    )}

  <div className="actions flex justify-between mt-6">
    {billPrinted ? (
      <>
        <button
          onClick={ReprinthandleGenerateBill}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Reprint Bill
        </button>
        <button
          onClick={handlePaymentComplete}
          disabled={paymentbtn}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Done Order
        </button>
      </>
    ) : (
      <button
        onClick={handleGenerateBill}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
        Generate Bill
      </button>
    )}
    
  </div>
</>
);


const OrderPayment = ({
  order,
  roomData,
  hotelData,
  DoneOrderBYbillNo,
  waiterlist,
  vpa,
}) => {
  const { generateOrderBill, completeOrderBill } = useOrder();
  const { orders: storedOrders } = useOrder();
  const [paymentMethod, setPaymentMethod] = useState();
  const [selectedWaiter, setSelectedWaiter] = useState(""); // Will hold fetched waiter ID
  const [discount, setDiscount] = useState(0);
  const [discountFlat, setDiscountFlat] = useState(0); // Will hold fetched flat discount
  const [tips, setTips] = useState(0);
  const [partialPayment, setPartialPayment] = useState({
    cash: 0,
    online: 0,
    card: 0,
  });
  const [billPrinted, setBillPrinted] = useState(false);
  const [error, setError] = useState("");
  const [setExtraDiscounts, setExtraDiscount] = useState(0);
  const [paymentbtn, setDisablePaymentButton] = useState(false);
  const[name,setName]=useState("Na");
  const [mobile, setMobile]= useState("Na")

  useEffect(() => {
    console.log(storedOrders);
    const tableOrder = storedOrders.find((order) => order.tableVPA === vpa);
    if (tableOrder && tableOrder.status === "KOTBillGenerated") {
      setSelectedWaiter(tableOrder.assignedWaiter);
      setDiscountFlat(tableOrder.discount);
      setBillPrinted(true);
      setName(tableOrder.username);
      setMobile(tableOrder.usermobile);
    }
  }, [storedOrders.length]);

  const handleGenerateBill = async () => {
    const totalAmount = Math.round(
      order[0].amount -
        (order[0].amount * (discount || 0)) / 100 -
        parseInt(discountFlat || 0)
    );
    const discounts = order[0].amount - totalAmount;
    try {
      await generateOrderBill({
        tableVPA: order[0].tableVPA,
        waiterid: selectedWaiter,
        discount: discounts,
        totalAmount,
        name,mobile
        
      });
      PrintBills(
        order[0].oldOrder,
        totalAmount,
        discounts,
        hotelData,
        roomData,
        order[0].billNo
      );
      setBillPrinted(true);
    } catch (e) {
      console.log(e);
    }
  };

  const ReprinthandleGenerateBill = async () => {
    const totalAmount = Math.round(order[0].amount);
    const discounts = parseInt(discountFlat || 0);
    PrintBills(
      order[0].oldOrder,
      totalAmount,
      discounts,
      hotelData,
      roomData,
      order[0].billNo
    );
  };

  const handlePaymentComplete = async () => {
    if (!paymentMethod) {
      setError("Please Select Payment Method");
      return;
    }
    setDisablePaymentButton(true);
    const billNo = order[0].billNo;
    const methods = [];
    let total = 0;
    if (paymentMethod === "partial") {
      methods.push({
        name: "cash",
        amount: partialPayment.cash,
      });
      total += parseInt(partialPayment.cash);
      methods.push({
        name: "online",
        amount: partialPayment.online,
      });
      total += parseInt(partialPayment.online);
      methods.push({
        name: "card",
        amount: partialPayment.card,
      });
      total += parseInt(partialPayment.card);
    } else {
      methods.push({
        name: paymentMethod,
        amount: parseFloat(order[0].amount) - parseFloat(setExtraDiscounts),
      });
    }
    if (
      paymentMethod === "partial" &&
      parseFloat(order[0].amount) - parseFloat(setExtraDiscounts) !== total
    ) {
      alert("Total Bill Not Correct ");
      setDisablePaymentButton(false);
      return;
    }

    const completeOrderPayload = {
      billNo,
      methods,
      tips,
      extraDiscount: setExtraDiscounts,
      paymentname: paymentMethod,
      tableVPA: vpa,
      name:name,
      mobile:mobile,
      tips: tips,
    };
    try {
      const response = await completeOrderBill(completeOrderPayload);
      if (response) {
        DoneOrderBYbillNo(billNo);
      }
    } catch (e) {
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="order-payment w-full max-w-md mx-auto p-4 bg-white shadow-lg rounded-md">
      <NameAndNoSection  name={name} mobile ={mobile} setName={setName} setMobile ={setMobile}/>

      <WaiterSelection
        selectedWaiter={selectedWaiter}
        setSelectedWaiter={setSelectedWaiter}
        waiterlist={waiterlist}
      />
      <DiscountSection
        billPrinted={billPrinted}
        discountFlat={discountFlat}
        setDiscountFlat={setDiscountFlat}
        discount={discount}
        setDiscount={setDiscount}
        setExtraDiscounts={setExtraDiscounts}
        setExtraDiscount={setExtraDiscount}
      />
      <BillSummary
        order={order}
        billPrinted={billPrinted}
        discount={discount}
        discountFlat={discountFlat}
        setExtraDiscounts={setExtraDiscounts}
      />
      {billPrinted && (
        <>
          <TipsInput tips={tips} setTips={setTips} />
          <PaymentMethodSelection
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            setError={setError}
          />
          {paymentMethod === "partial" && (
            <PartialPaymentInputs
              partialPayment={partialPayment}
              setPartialPayment={setPartialPayment}
            />
          )}
          {error && <p className="text-red-500 mt-0 text-sm">{error}</p>}
        </>
      )}
      <Actions
        billPrinted={billPrinted}
        handleGenerateBill={handleGenerateBill}
        ReprinthandleGenerateBill={ReprinthandleGenerateBill}
        handlePaymentComplete={handlePaymentComplete}
        paymentbtn={paymentbtn}
        order={order}
        setExtraDiscounts={setExtraDiscounts}
      />
    </div>
  );
};

export default OrderPayment;
