import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  cancleExtraItemKOT,
  cancleItemKOT,
} from "./MenuAppServices/Manuapp.services";
import store from "../../../../store";

const OldCartItem = ({ item, handleKotCancle, billNo }) => {
  const { restaurantPin } = store;
  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState("");
  const [reason, setReason] = useState("");
  console.log(item, "cancl");

  const cancleKOT = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setPassword("");
    setReason("");
  };

  const handleDone = async () => {
    const setPassword = localStorage.getItem("canclepassword");
    if (password === setPassword) {
      handleKotCancle(item.itemId, { ...item, reason });
      handleCloseModal();
    }
  };

  return (
    <>
      <li className="flex justify-between items-center mb-2">
        <div>
          <h4>{item.name}</h4>
        </div>
        <div>
          <h4>{item.quantity}</h4>
        </div>
        <div className="flex items-center">
          <button className="ml-4 text-red-500" onClick={cancleKOT}>
            Cancel
          </button>
        </div>
      </li>
      {/* {console.log(item, "........./...")} */}

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded shadow-lg">
            <div className="flex justify-between">
              <h2 className="text-lg font-semibold mb-4">Cancel KOT</h2>
              <button className="px-4 py-2 rounded" onClick={handleCloseModal}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div className="flex justify-between">
              <div>{item.name}</div>
              <div>{item.quantity}</div>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 p-2 border rounded w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">Reason:</label>
              <textarea
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                className="mt-1 p-2 border rounded w-full"
              />
            </div>
            <div className="flex justify-end space-x-2">
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded"
                onClick={handleDone}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OldCartItem;
