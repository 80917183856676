import React, { createContext, useEffect } from "react";
import "./App.css";
import TableOverview from "./Component/TableOverview";
import Header from "./Component/Header/Header";
import Reports from "./Component/Report/Report";
import AcceptOrder from "./Component/AcceptOrder/AcceptOrder.main";
import OrderListLifeTime from "./Component/Report/OrdersListLifetime";
import OrderMain from "./Component/Orders/OrderMain";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import AuthGuard from "./Component/AuthGard/AuthGuard";
import Login from "./Component/AuthGard/LogIn";
import ItemManagement from "./Component/Menu/MenuManagament";
import Setting from "./Component/Setting/Setting";
import SignUp from "./Component/AuthGard/SignUp";
import { ParcelMain } from "./Component/Parcel/Parcel.main";
import toast, { Toaster } from "react-hot-toast";

import { useCloud } from "./hooks/useCloud";
import { useStore } from "./hooks/useStore";
import store from "./store/index";
import { openDB } from "idb";
import SignUpNew from "./Component/AuthGard/SignUpNew";
import ManagerForm from "./Component/AuthGard/ManagerForm";
import Room from "./Component/AuthGard/Room";
import Managment from "./Component/Managment/Managment";
const DB_NAME = "orderDB";
const IN_PROCESS_ORDERS_STORE_NAME = "InProcessOrders";
const COMPLETE_ORDERS_STORE_NAME = "completedOrders";
const ORDER_HISTORY_STORE_NAME = "orderHistory";
const DB_VERSION = 1;

function App() {
  useEffect(() => {
    const initDB = async () => {
      const db = await openDB(DB_NAME, DB_VERSION, {
        upgrade(db) {
          if (!db.objectStoreNames.contains(IN_PROCESS_ORDERS_STORE_NAME)) {
            db.createObjectStore(IN_PROCESS_ORDERS_STORE_NAME, {
              keyPath: "tableVPA",
            });
          }
          if (!db.objectStoreNames.contains(COMPLETE_ORDERS_STORE_NAME)) {
            db.createObjectStore(COMPLETE_ORDERS_STORE_NAME, {
              keyPath: "billNo",
            });
          }
          if (!db.objectStoreNames.contains(ORDER_HISTORY_STORE_NAME)) {
            db.createObjectStore(ORDER_HISTORY_STORE_NAME, {
              keyPath: "billNo",
            });
          }
        },
      });
      store.setDBInstance(db);
    };

    initDB();
  }, []);

  useStore();

  return (
    <Router>
      <AppContent />
    </Router>
  );
}

const AppContent = () => {
  const location = useLocation();

  // Conditionally render the Header based on the current route
  const shouldShowHeader = ![
    "/login",
    "/signup",
    "/newsignup",
    "/ManagerForm",
    "/Rooms",
  ].includes(location.pathname);

  return (
    <div className="min-h-screen">
      <Toaster position="top-right" />
      {/* Sidebar - Header component */}
      {shouldShowHeader && (
        <div className="bg-white">
          <Header />
        </div>
      )}

      {/* Main content */}
      <main
        style={{ background: "#f0f0f0" }}
        className={
          shouldShowHeader
            ? "mt-20 ml-40 flex-1 absolute left-0 top-0 right-0 "
            : "flex-1"
        }
      >
        <div
          className={`${
            shouldShowHeader
              ? "max-w-7xl mx-auto py-6 sm:px-6 lg:px-8"
              : "no-spacing-div"
          }`}
        >
          <div
            className={`${
              shouldShowHeader ? "px-4 py-6 sm:px-0" : "no-spacing-route"
            }`}
          >
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/newsignup" element={<SignUpNew />} />
              <Route path="/managerform" element={<ManagerForm />} />
              <Route path="/rooms" element={<Room />} />
              <Route
                path="/table-overview"
                element={
                  <AuthGuard>
                    <TableOverview />
                  </AuthGuard>
                }
              />
              <Route
                path="/reports"
                element={
                  <AuthGuard>
                    <Reports />
                  </AuthGuard>
                }
              />
              <Route
                path="/accept-order"
                element={
                  <AuthGuard>
                    <AcceptOrder />
                  </AuthGuard>
                }
              />
              <Route
                path="/menu-managment"
                element={
                  <AuthGuard>
                    <ItemManagement />
                  </AuthGuard>
                }
              />
              <Route
                path="/setting"
                element={
                  <AuthGuard>
                    <Setting />
                  </AuthGuard>
                }
              />
              <Route
                path="/order-list-lifetime"
                element={
                  <AuthGuard>
                    <OrderListLifeTime />
                  </AuthGuard>
                }
              />
              <Route
                path="/order-main"
                element={
                  <AuthGuard>
                    <OrderMain />
                  </AuthGuard>
                }
              />
              <Route
                path="/order-parcel"
                element={
                  <AuthGuard>
                    <ParcelMain />
                  </AuthGuard>
                }
              />
              <Route
                path="/hotel-managment"
                element={
                  <AuthGuard>
                    <Managment/>
                  </AuthGuard>
                }
              />
              <Route path="*" element={<Navigate to="/table-overview" />} />{" "}
              {/* Redirect to table overview if route is unknown */}
            </Routes>
          </div>
        </div>
      </main>
    </div>
  );
};

export default App;
