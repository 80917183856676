import { makeAutoObservable, runInAction, computed } from "mobx";

class Store {
  dbInstance = null
  categories = [];
  items = [];
  rooms = [];
  orders = [];
  bills = [];
  records = [];
  printedKOTs = [];
  printedKOT_VPA = [];
  waiters = [];
  completeMenu = [];
  lastBillNo = 0;
  restaurantPin = "1234";
  orderHistory = [];
  tables = {};
  digitalOrderVPAs = [];
  reload =true;
  constructor() {
    makeAutoObservable(this, {
      totalBillAmount: computed,
      printedKOTsByTableVPA: computed,
    });
    this.loadFromLocalStorage();
    // this.syncWithCloud();
  }

  setDBInstance (instance) {
    this.dbInstance = instance
  }

  addCategories(categories) {
    this.categories = categories;
    this.saveToLocalStorage();
  }

  addItems(items) {
    this.items = items;
    this.saveToLocalStorage();
  }

  addRooms(rooms) {
    this.rooms = rooms;
    this.tables = rooms.reduce((acc, room) => {
      const { tableData } = room;
      tableData.forEach(table => {
        acc[table.qrpidVPA] = table
      })
      return acc
    },{})
    this.saveToLocalStorage();
  }
  forceReload(){
    this.reload=!this.reload;
  }

  addOrders(orders) {
    this.orders.push(...orders);
    this.saveToLocalStorage();
  }

  addBills(bills) {
    this.bills.push(...bills);
    this.saveToLocalStorage();
  }

  addRecords(records) {
    this.records.push(...records);
    this.saveToLocalStorage();
  }

  addPrintedKOTs(printedKOTs) {
    this.printedKOTs = printedKOTs;
    this.saveToLocalStorage();
  }

  pushPrintedKOTs(printedKOTs) {
    this.printedKOTs.push(printedKOTs);
    this.saveToLocalStorage();
  }

  updatePrintedKOTs(printedKOT) {
    this.printedKOTs = this.printedKOTs.map((kot) =>
      kot.tableVPA === printedKOT.tableVPA ? printedKOT : kot
    );
    this.saveToLocalStorage();
  }

  addPrintedKOT_VPA(printedKOT_VPA) {
    this.printedKOT_VPA = printedKOT_VPA;
    this.saveToLocalStorage();
  }

  pushPrintedKOT_VPA(printedKOT_VPA) {
    this.printedKOT_VPA.push(printedKOT_VPA);
    this.saveToLocalStorage();
  }

  removePrintedKOT_VPA(tableVPA) {
    this.printedKOT_VPA = this.printedKOT_VPA.filter(
      (kot) => kot.tableVPA !== tableVPA
    );
    this.saveToLocalStorage();
  }
  removePrintedKOT(tableVPA) {
    this.printedKOTs = this.printedKOTs.filter(
      (kot) => kot.tableVPA !== tableVPA
    );
    this.saveToLocalStorage();
  }

  addWaiters(waiters) {
    this.waiters = waiters;
    this.saveToLocalStorage();
  }

  removeCategories(categoryIds) {
    this.categories = this.categories.filter(
      (category) => !categoryIds.includes(category.id)
    );
    this.saveToLocalStorage();
  }

  removeItems(itemIds) {
    this.items = this.items.filter((item) => !itemIds.includes(item.id));
    this.saveToLocalStorage();
  }

  removeRooms(roomIds) {
    this.rooms = this.rooms.filter((room) => !roomIds.includes(room.id));
    this.saveToLocalStorage();
  }

  removeOrders(orderIds) {
    this.orders = this.orders.filter((order) => !orderIds.includes(order.id));
    this.saveToLocalStorage();
  }

  removeBills(billIds) {
    this.bills = this.bills.filter((bill) => !billIds.includes(bill.id));
    this.saveToLocalStorage();
  }

  removeRecords(recordIds) {
    this.records = this.records.filter(
      (record) => !recordIds.includes(record.id)
    );
    this.saveToLocalStorage();
  }

  removeWaiters(waiterIds) {
    this.waiters = this.waiters.filter(
      (waiter) => !waiterIds.includes(waiter.id)
    );
    this.saveToLocalStorage();
  }

  updateCompleteMenu(menu) {
    this.completeMenu = menu;
    this.saveToLocalStorage();
  }

  getLastBillNo() {
    return this.lastBillNo;
  }

  setLastBillNo(billNo) {
    this.lastBillNo = Number(billNo);
    localStorage.setItem("lastBillNo", Number(billNo));
    this.saveToLocalStorage();
  }

  setDigitalOrderVPA (vpas) {
    console.log(vpas);
    this.digitalOrderVPAs = vpas
    this.saveToLocalStorage()
  }

  removeDigitalOrderVPA (vpa) {
    this.digitalOrderVPAs = this.digitalOrderVPAs.filter(exitingVpa => exitingVpa !== vpa)
    this.saveToLocalStorage()
  }

  incrementLastBillNo() {
    this.lastBillNo += 1;
    this.saveToLocalStorage();
  }
  switchTableUpdate(from ,to){

   const data = this.printedKOTs.map((order) => {
    if (order.tableVPA === from) {
      return { ...order, tableVPA: to };
    }
    return order;
  });;
   console.log(data);
   this.printedKOTs=data;
   this.saveToLocalStorage();

  }
  //  updateTableVPA = (data, fromVPA, toVPA) => {
  //   return data.map((order) => {
  //     if (order.tableVPA === fromVPA) {
  //       return { ...order, tableVPA: toVPA };
  //     }
  //     return order;
  //   });
  // };
  

  get totalBillAmount() {
    return this.printedKOTs.reduce((total, bill) => total + bill.amount, 0);
  }

  get printedKOTsByTableVPA() {
    return this.printedKOTs.reduce((acc, kot) => {
      acc[kot.tableVPA] = kot;
      return acc;
    }, {});
  }

  saveToLocalStorage() {
    localStorage.setItem(
      "store",
      JSON.stringify({
        categories: this.categories,
        items: this.items,
        rooms: this.rooms,
        tables: this.tables,
        orders: this.orders,
        bills: this.bills,
        records: this.records,
        printedKOTs: this.printedKOTs,
        printedKOT_VPA: this.printedKOT_VPA,
        waiters: this.waiters,
        completeMenu: this.completeMenu,
        lastBillNo: this.lastBillNo,
        orderHistory: this.orderHistory,
        digitalOrderVPAs: this.digitalOrderVPAs
      })
    );
  }

  loadFromLocalStorage() {
    const data = JSON.parse(localStorage.getItem("store"));
    console.log(data);
    if (data) {
      runInAction(() => {
        this.categories = data.categories || [];
        this.items = data.items || [];
        this.rooms = data.rooms || [];
        this.tables = data.tables || {}
        this.orders = data.orders || [];
        this.bills = data.bills || [];
        this.records = data.records || [];
        this.printedKOTs = data.printedKOTs || [];
        this.printedKOT_VPA = data.printedKOT_VPA || [];
        this.waiters = data.waiters || [];
        this.completeMenu = data.completeMenu || [];
        this.lastBillNo = data.lastBillNo || 0;
        this.orderHistory = data.orderHistory || []
        this.digitalOrderVPAs = data.digitalOrderVPAs || []
      });
    }
  }

  async syncWithCloud() {
    if (navigator.onLine) {
      // Fetch data from cloud
      const response = await fetch("https://api.example.com/data");
      const data = await response.json();
      runInAction(() => {
        this.categories = data.categories;
        this.items = data.items;
        this.rooms = data.rooms;
        this.orders = data.orders;
        this.bills = data.bills;
        this.records = data.records;
        this.printedKOTs = data.kotPrintedItem;
        this.printedKOT_VPA = data.vpaKotPrinted.map((item) => item.tableVPA);
        this.waiters = data.waiters;
        this.updateCompleteMenu();
        this.lastBillNo = data.lastBillNo || 0;
      });
      this.saveToLocalStorage();
    }
  }

  updateOrderHistory(orders) {
    const { event, data} = orders;
    if(event) 
    {
      this.orderHistory = data
      this.saveToLocalStorage()
    }
  }
}

const store = new Store();
export default store;
