import { useState } from "react";

import MenuApp from "./MenuApp/MenuApp";

const CategorySelection = ({
  data,
  menu,
  bookedOrder,
  closeEdit,
  billNo,
  oldKOTNotPrinted,
}) => {
  return (
    <MenuApp
      data={data}
      menu={menu}
      bookedOrder={bookedOrder}
      closeEdit={closeEdit}
      billNo={billNo}
      oldKOTNotPrinted={oldKOTNotPrinted}
    />
  );
};

export default CategorySelection;
