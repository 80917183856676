import React, { useEffect, useState } from 'react';
import { useCloud } from '../../../hooks/useCloud';

const SetACCharge = () => {
  const {gstUpdate} = useCloud();
  const [state, setState] = useState({
    gstActive: false,
    selectedValue: '',
    gstn: '',
    sgst: '',
    cgst: '',
    acSgst: '',
    acCgst: '',
    vat: '',
    serviceCharge:'',
  });
  const [editOption, setEditOption] = useState(false);

  useEffect(() => {
    const isGST = localStorage.getItem('isGST') === 'true';
    setState((prev) => ({ ...prev, gstActive: isGST }));
  }, []);

  const handleStateChange = (key, value) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };

  const toggleGST = () => {
    const updatedGstActive = !state.gstActive;
    localStorage.setItem('isGST', updatedGstActive);
    if(!updatedGstActive)setEditOption(false);
    setState((prev) => ({ ...prev, gstActive: updatedGstActive }));
  };

  const toggleGSTEdit = () => {
    setEditOption(!editOption);
  };

  const handleSave = async() => {
    const { gstn, selectedValue, sgst, cgst, acSgst, acCgst, vat } = state;
    const data = await gstUpdate(state);
   console.log(data);
   

    localStorage.setItem('gstPriceType', selectedValue);
    localStorage.setItem('GSTN', gstn);
    localStorage.setItem('SGST', sgst);
    localStorage.setItem('CGST', cgst);
    localStorage.setItem('AC_SGST', acSgst);
    localStorage.setItem('AC_CGST', acCgst);
    localStorage.setItem('VAT', vat);

    alert('GST and tax details saved successfully!');
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md max-w-2xl mx-auto mt-8">
      {/* Header */}
      <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">GST & Tax Configuration</h2>

      {/* GST Toggle */}
      <div className="flex justify-between items-center mb-6">
        <button
          onClick={toggleGST}
          className={`px-4 py-2 text-sm font-medium rounded-md transition ${
            state.gstActive
              ? 'bg-red-500 text-white hover:bg-red-600'
              : 'bg-green-500 text-white hover:bg-green-600'
          }`}
        >
          {state.gstActive ? 'Deactivate GST' : 'Activate GST'}
        </button>

        {state.gstActive && (
          <button
            onClick={toggleGSTEdit}
            className={`px-4 py-2 text-sm font-medium rounded-md transition ${
              editOption
                ? 'bg-gray-500 text-white hover:bg-gray-600'
                : 'bg-blue-500 text-white hover:bg-blue-600'
            }`}
          >
            {editOption ? 'Close Edit' : 'Edit GST'}
          </button>
        )}
      </div>

      {/* GST Form */}
      {editOption && (
        <div className="space-y-6">
          {/* Pricing Type */}
          <div>
            <label className="block text-gray-600 font-semibold mb-2">
              Select Pricing Type
            </label>
            <select
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={state.selectedValue}
              onChange={(e) => handleStateChange('selectedValue', e.target.value)}
            >
              <option value="">Select Pricing Type</option>
              <option value="Include">Include</option>
              <option value="Exclude">Exclude</option>
            </select>
          </div>

          {/* GSTN Input */}
          <div>
            <label className="block text-gray-600 font-semibold mb-2">GSTN Number</label>
            <input
              type="text"
              placeholder="Enter your GSTN"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={state.gstn}
              onChange={(e) => handleStateChange('gstn', e.target.value)}
            />
          </div>

          {/* Tax Percentages */}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-600 font-semibold mb-2">SGST (%)</label>
              <input
                type="number"
                placeholder="Enter SGST"
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={state.sgst}
                onChange={(e) => handleStateChange('sgst', e.target.value)}
              />
            </div>
            <div>
              <label className="block text-gray-600 font-semibold mb-2">CGST (%)</label>
              <input
                type="number"
                placeholder="Enter CGST"
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={state.cgst}
                onChange={(e) => handleStateChange('cgst', e.target.value)}
              />
            </div>
            <div>
              <label className="block text-gray-600 font-semibold mb-2">AC SGST (%)</label>
              <input
                type="number"
                placeholder="Enter AC SGST"
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={state.acSgst}
                onChange={(e) => handleStateChange('acSgst', e.target.value)}
              />
            </div>
            <div>
              <label className="block text-gray-600 font-semibold mb-2">AC CGST (%)</label>
              <input
                type="number"
                placeholder="Enter AC CGST"
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={state.acCgst}
                onChange={(e) => handleStateChange('acCgst', e.target.value)}
              />
            </div>
            <div className="col-span-2">
              <label className="block text-gray-600 font-semibold mb-2">VAT (%)</label>
              <input
                type="number"
                placeholder="Enter VAT"
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={state.vat}
                onChange={(e) => handleStateChange('vat', e.target.value)}
              />
            </div>
            <div className="col-span-2">
              <label className="block text-gray-600 font-semibold mb-2">Service Charge (%)</label>
              <input
                type="number"
                placeholder="Enter Service Charge"
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={state.serviceCharge}
                onChange={(e) => handleStateChange('serviceCharge', e.target.value)}
              />
            </div>
          </div>

        </div>
      )}
          
         { <button 
            onClick={handleSave}
            className="w-full py-2 mt-10 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-400"
          >
            Save Details
          </button>}
    </div>
  );
};

export default SetACCharge;
