import React from "react";
import { Navigate } from "react-router-dom";

function isAuthenticated() {
  const token = localStorage.getItem("authToken");
  if (!token) {
    return false;
  }

  const payload = JSON.parse(atob(token.split(".")[1]));
  const expirationTime = payload.exp * 1000;

  if (Date.now() >= expirationTime) {
    localStorage.removeItem("authToken");
    return false;
  }
  return true;
}

const AuthGuard = ({ children }) => {
  const isAuth = isAuthenticated();

  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default AuthGuard;
