import React, { useState, useEffect } from "react";

const RoomsAndTablesForm = () => {
  const [noOfRooms, setNoOfRooms] = useState(1);
  const [rooms, setRooms] = useState([
    { roomName: "", noOfTables: 0, isAC: false },
  ]);

  // Load initial data from localStorage
  useEffect(() => {
    const storedData = localStorage.getItem("roomsData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setNoOfRooms(parsedData.length);
      setRooms(parsedData);
    }
  }, []);

  // Handle input changes for room details
  const handleRoomChange = (index, field, value) => {
    const updatedRooms = [...rooms];
    updatedRooms[index] = {
      ...updatedRooms[index],
      [field]: value,
    };
    setRooms(updatedRooms);
    localStorage.setItem("roomsData", JSON.stringify(updatedRooms));
  };

  // Handle number of rooms change
  const handleNoOfRoomsChange = (e) => {
    const count = Number(e.target.value);
    setNoOfRooms(count);

    const updatedRooms = [...rooms];
    while (updatedRooms.length < count) {
      updatedRooms.push({ roomName: "", noOfTables: 0, isAC: false });
    }
    while (updatedRooms.length > count) {
      updatedRooms.pop();
    }
    setRooms(updatedRooms);
    localStorage.setItem("roomsData", JSON.stringify(updatedRooms));
  };

  return (
    <div className="flex flex-col md:flex-row h-screen">
      {/* Left Section */}
      <div className="w-full md:w-1/4 bg-gradient-to-b from-[#16A34A] to-[#035722] text-white p-8 fixed top-0 left-0 h-full z-10">
        <h1 className="text-2xl font-bold mb-4">Step 2</h1>
        <p className="mb-8">Create Manager's Profile</p>
        <ul className="space-y-6">
          <li className="flex items-center">
            <div className="w-6 h-6 rounded-full bg-white text-green-600 flex items-center justify-center font-bold">
              1
            </div>
            <span className="ml-4">Admin detail</span>
          </li>
          <li className="flex items-center">
            <div className="w-6 h-6 rounded-full bg-white text-green-600 flex items-center justify-center font-bold">
              2
            </div>
            <span className="ml-4">Manager's data</span>
          </li>
          <li className="flex items-center">
            <div className="w-6 h-6 rounded-full bg-white text-green-600 flex items-center justify-center font-bold">
              3
            </div>
            <span className="ml-4">Room's and tables</span>
          </li>
        </ul>
      </div>

      {/* Right Section for Rooms Form */}
      <div className="w-full md:w-3/4 bg-white p-8 ml-[25%] overflow-y-auto h-screen">
        <form>
          {/* Number of Rooms Selector */}
          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">
              No of rooms:
            </label>
            <select
              value={noOfRooms}
              onChange={handleNoOfRoomsChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
            >
              {[1, 2, 3, 4, 5].map((num) => (
                <option key={num} value={num}>
                  {num}
                </option>
              ))}
            </select>
          </div>

          {/* Render Room Details */}
          {rooms.map((room, index) => (
            <div
              key={index}
              className="mb-6 border border-gray-200 rounded-lg p-6 bg-gray-50 shadow-sm"
            >
              <h3 className="text-lg font-semibold mb-4 text-gray-700">
                Room {index + 1}
              </h3>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Room Name
                  </label>
                  <input
                    type="text"
                    value={room.roomName}
                    onChange={(e) =>
                      handleRoomChange(index, "roomName", e.target.value)
                    }
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Tables in room
                  </label>
                  <input
                    type="number"
                    value={room.noOfTables}
                    onChange={(e) =>
                      handleRoomChange(index, "noOfTables", e.target.value)
                    }
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                  />
                </div>
              </div>

              {/* Air Conditioned */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div className="flex">
                  <input
                    type="checkbox"
                    checked={room.isAC}
                    onChange={(e) =>
                      handleRoomChange(index, "isAC", e.target.checked)
                    }
                    className="h-5 w-5 text-green-500 focus:ring-green-400 border-gray-300 rounded"
                  />
                  <label htmlFor="isAC" className="ml-2 text-sm text-gray-700">
                    Air Conditioned
                  </label>
                </div>
              </div>
            </div>
          ))}

          {/* Footer Buttons */}
          <div className="flex flex-col md:flex-row items-center justify-between gap-4 md:gap-0 mt-16">
            <button
              type="button"
              className="px-4 py-2 bg-[#979797] rounded-full text-white font-extrabold hover:bg-[#b0b0b0] hover:text-white w-full md:w-auto"
            >
              Back
            </button>
            <button
              type="submit"
              onClick={() => alert("Data saved successfully!")}
              className="px-6 py-2 bg-green-600 text-white font-extrabold rounded-full hover:bg-green-700 w-full md:w-auto"
            >
              Save Manager
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RoomsAndTablesForm;
