import React, { useState } from "react";
import {
  cancleOrder,
  changePaymentMethod,
  extraItemgetByBill,
} from "./orderService/orderService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPrint } from "@fortawesome/free-solid-svg-icons";
import { Reprint } from "../Print/ReprintBill";
import useOrder from "../../hooks/useOrder";

const OrderList = ({ orders, onSort }) => {
  const { changeOrderPaymentMethod } = useOrder();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBillNo, setSelectedBillNo] = useState(null);
  const [selectedOrderItems, setSelectedOrderItems] = useState([]); // To store selected order items
  const [password, setPassword] = useState("");
  const [reason, setReason] = useState("");
  const [error, setError] = useState("");
  const [isCancleOrder, setIsCancleOrder] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState();

  // console.log(orders, "......");
  // Handle opening the modal for cancellation
  const handleOpenCancelModal = (billNo) => {
    setSelectedBillNo(billNo);

    setIsCancleOrder(true);
    setError(""); // Reset error when opening the modal
  };

  // Handle closing the modal for cancellation
  const handleCloseModal = () => {
    setIsCancleOrder(false);
    // isCancleOrder
    setPassword("");
    setReason("");
    setError("");
  };

  // Handle form submission (Cancel order)
  const handleCancel = async () => {
    if (!paymentMethod) {
      setError("Select Payment Method.");
      return;
    }

    // console.log('Cancel order:', { billNo: selectedBillNo, password, reason });
    const isSuccess = await changeOrderPaymentMethod({
      billNo: selectedBillNo,
      paymentname: paymentMethod,
    });

    if(isSuccess)
    {
      handleCloseModal();
      
    }else {
      setError("something went wrong, please try again");
    }
  };

  // Handle opening the modal for viewing order items
  const handleViewItems = async (order) => {
    // console.log(order, "this is view ");

    if (!order?.itemsforView) {
      const orderdata = await extraItemgetByBill({ billNo: order.id });

      // console.log(orderdata.data, "....order ");
      if (orderdata.data?.event) {
        setSelectedOrderItems(orderdata.data); // Set the selected order's items
      }
    } else {
      setSelectedOrderItems({
        event: true,
        items: order.itemsforView,
        order: {
          amount: order.amount,
          billNo: order.id,
          discount: order.discount,
          tableVPA: order.tableVPA,
          tips: order.tips,
        },
      });
    }

    setSelectedBillNo(order.id);
    setIsModalOpen(true); // Open the modal
  };

  // Handle printing (this can be expanded with actual print logic)
  const handlePrint = async (order) => {
    const hotelData = {
      name: localStorage.getItem("name"),
      address: localStorage.getItem("address"),
    };

    const roomData = {
      table: order?.tableVPA ?order?.tableVPA.match(/t(\d+)@/)[1]:"Na",
    };
    // Basic print functionality
    if (!order.itemsforView) {
      const orderdata = await extraItemgetByBill({ billNo: order.id });

      // console.log(orderdata, "....order ");
      if (orderdata.data?.event) {
        setSelectedOrderItems(orderdata.data);
        roomData.table=orderdata.data.order.tableVPA.match(/t(\d+)@/)[1];
        
        Reprint(
          order.id,
          orderdata.data.order?.discount,
          orderdata.data.order?.amount,
          orderdata.data.items,
          hotelData,
          roomData
        );// Set the selected order's items
      }
    } else {
      setSelectedOrderItems({
        event: true,
        items: order.itemsforView,
        order: {
          amount: order.amount,
          billNo: order.id,
          discount: order.discount,
          tableVPA: order.tableVPA,
          tips: order.tips,
        },
      });
      Reprint(
        order.id,
        order?.discount,
       order?.amount,
        order.itemsforView,
        hotelData,
        roomData
      );
    }

    setSelectedBillNo(order.id);

  

    // Call Reprint function after 3 seconds

  
    // 3 seconds delay
  };

  return (
    <>
      <table className="w-full text-left">
        <thead>
          <tr>
            <th onClick={() => onSort("id")} className="cursor-pointer">
              Bill No
            </th>
            <th onClick={() => onSort("table")} className="cursor-pointer">
              Table
            </th>
            <th>Payment Type </th>
            <th>My Amount </th>
            <th>Discount </th>
            <th>Grand Total </th>
            <th>Tips</th>
            <th>Status</th>
            <th>Cancel</th>
            <th onClick={() => onSort("time")} className="cursor-pointer">
              Date/Time
            </th>
            <th>View/Print </th>
          </tr>
        </thead>
        {/* "id": 191,
        "table": "T 1",
        "items": [
            "XYZ Item - half ",
            "Grilled Chicken Sandwich - Extra Cheese"
        ],
        "time": "24/9/2024, 1:42:29 pm",
        "tips": "2",
        "paymentMethod": "cash",
        "discount": "1",
        "status": "Paid" */}
        <tbody>
          {orders.map((order, index) => (
            <tr key={index} className="border-t">
              <td>{order.id}</td>
              <td>{order.table}</td>
              <td>
                <p>
                  <strong>{order.paymentMethod}</strong>
                </p>
                <p>
                  {order.paymentMethod == "Partial" &&
                    order?.partial.map((ele) => (
                      <>
                        <p>
                          {ele.name}:{ele.amount}
                        </p>
                      </>
                    ))}
                </p>
              </td>
              <td>{parseFloat(order.amount) + parseFloat(order.discount)}</td>
              <td>{parseFloat(order.discount).toFixed(2)}</td>
              <td>{parseFloat(order.amount)}</td>
              <td>{order.tips}</td>
              <td>{order.status}</td>
              <td>
                {order.status === "Paid" && order.paymentMethod == "pending" ? (
                  <button
                    className="bg-red-500 text-white p-2 m-3 rounded"
                    onClick={() => handleOpenCancelModal(order.id)}
                  >
                    Change Method
                  </button>
                ) : (
                  "-"
                )}
              </td>
              <td>{order.time}</td>
              <td className="pl-5 text-green-700">
                {order.status === "Paid" && (
                  <>
                    <span
                      className="cursor-pointer"
                      onClick={() => handleViewItems(order)}
                    >
                      <FontAwesomeIcon icon={faEye} className="mr-3" />
                    </span>
                    <span
                      className="cursor-pointer"
                      onClick={() => handlePrint(order)}
                    >
                      <FontAwesomeIcon icon={faPrint} className="mr-3" />
                    </span>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for viewing items */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-lg w-96">
            <h2 className="text-xl mb-4">
              Order Items (Bill No: {selectedBillNo})
            </h2>

            {/* List of order items */}
            <ul className="mb-4">
              {selectedOrderItems?.items &&
                selectedOrderItems?.items.map((item, index) => (
                  <div className="flex justify-between">
                    <li key={index} className="border-b py-2 max-w-32">
                      {item?.name}
                    </li>
                    <li key={index} className="border-b py-2">
                      {item?.quantity}
                    </li>
                    <li key={index} className="border-b py-2">
                      {item?.price}
                    </li>
                  </div>
                ))}
              {selectedOrderItems?.order?.discount && (
                <h3 className="mt-10">
                  Discount:-{" "}
                  <strong>{selectedOrderItems?.order?.discount}</strong>
                </h3>
              )}
              {selectedOrderItems?.order?.tips && (
                <h3>
                  Tips:- <strong>{selectedOrderItems?.order?.tips}</strong>
                </h3>
              )}
              <h3>
                Total Bill:-{" "}
                <strong>{selectedOrderItems?.order?.amount}</strong>
              </h3>
            </ul>

            {/* Print button */}

            {/* Close modal */}
            <button
              className="bg-gray-500 text-white p-2 rounded w-full mt-2"
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
      {isCancleOrder && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-lg">
            {/* Display error message if fields are empty */}
            {error && <p className="text-red-500 mb-4">{error}</p>}

            <h3 className="text-lg font-semibold mb-4">Payment Method</h3>

            <select
              value={paymentMethod}
              onChange={(e) => {
                setPaymentMethod(e.target.value);
                setError("");
              }}
              className="block w-full mb-1 p-2 border border-gray-300 rounded"
            >
              <option value="">Select Payment Method</option>
              <option value="cash">Cash</option>
              <option value="online">Online</option>
              <option value="card">Card</option>
              {/* <option value="partial">Partial</option>
            <option value="pending">Pending</option> */}
            </select>

            <div className="flex justify-end">
              <button
                className="bg-gray-500 text-white p-2 rounded mr-2"
                onClick={handleCloseModal}
              >
                Close
              </button>
              <button
                className="bg-red-500 text-white p-2 rounded"
                onClick={handleCancel}
              >
                Change Method
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderList;
