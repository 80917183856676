import React, { useEffect, useRef, useState } from "react";
import Header1 from "./Header/Header2";
import AcceptOrder from "./AcceptOrder/AcceptOrder.main";
import {
  GetTableData,
  RequestedKOTData,
  TableData,
} from "./services/table.Services";
import { FaEye } from "react-icons/fa6";
import { requestFcmToken } from "../utils/firebaseUtils";
import { useNavigate } from "react-router-dom";
import { playRing } from "./PlaySound";

import store from "../store/index";
import { observer } from "mobx-react-lite";
import useOrder from "../hooks/useOrder";
import { useCloud } from "../hooks/useCloud";
import Switchtable from "./Switchtable";

const RoomCard = ({ room, onClick, activeRoom }) => (
  <button
    onClick={() => onClick(room)}
    className={`w-full py-2 mb-2 rounded-md text-sm font-medium ${
      activeRoom === room.roomName
        ? "bg-gray-800 text-white"
        : "bg-gray-200 text-gray-700"
    }`}
  >
    {room.roomName}
  </button>
);

const TableCard = ({
  table,
  handleOpenPrompt,
  ordertables,
  printedKOT,
  printedKOTAll,
  room,
}) => { 
  
  const [tableStatus, setTableStatus] = useState("VACANT");
  const { getTableOrderStatus } = useOrder();
  const fetchTableStatus = async () => {
    const status = await getTableOrderStatus(table.qrpidVPA);
    console.log(status);
    if(status && status !== "NA")
    {
      setTableStatus(status);
    }
  }
  useEffect(() => {
    fetchTableStatus();
  }, [store.dbInstance, ]);

  useEffect(() => {
    window.addEventListener('digitalOrderProcessed', () => {
      fetchTableStatus();
    })
    return () => {
      window.removeEventListener('digitalOrderProcessed', () => {
        fetchTableStatus();
      })
    }
  }, []);
  
  return (
    <div
      onClick={() => handleOpenPrompt(table.tableNo, table.qrpidVPA, room)}
      key={table.tableNo}
      className={`rounded-lg h-20 w-20 text-center cursor-pointer text-lg font-bold w-full aspect-w-1 aspect-h-1 flex flex-col items-center justify-center
      ${tableStatus === "VACANT" && "bg-gray-300 text-gray-500"}
      ${tableStatus === "KOTBillGenerated" && "bg-purple-400 text-white"}
      ${tableStatus === "KOTPrint" && "bg-green-500 text-white"}
      ${tableStatus === "KOTUpdated" && "bg-green-500 text-white"}
      ${tableStatus === "RequestKOT" && "bg-blue-500 text-white"}`}
    >
      <div>T {table.tableNo}</div>
      {printedKOT.includes(table.qrpidVPA) && (
        <div className="text-sm font-normal">
          <strong>
            ₹{" "}
            {parseFloat(
              printedKOTAll.find((item) => item.tableVPA === table.qrpidVPA)
                ?.amount
            ).toFixed(2)}
          </strong>
          <div className="flex justify-center mt-1 ">
            <FaEye className="text-lg cursor-pointer" />
          </div>
        </div>
      )}
    </div>
  );};

const Legend = ({
  isPopoverOpen,
  togglePopover,
  resData,
  handleClosePromptBYSwitch,
}) => (
  <>
    <div className="mb-4 flex justify-between">
      <div className="space-x-4">
        <span className="text-gray-500">Legend:</span>
        <span className="inline-flex items-center">
          <span className="w-4 h-4 bg-gray-300 inline-block mr-1 rounded"></span>{" "}
          Blank Table
        </span>
        <span className="inline-flex items-center">
          <span className="w-4 h-4 bg-blue-500 inline-block mr-1 rounded"></span>{" "}
          Request KOT
        </span>
        <span className="inline-flex items-center">
          <span className="w-4 h-4 bg-green-500 inline-block mr-1 rounded"></span>{" "}
          Printed Table
        </span>
      </div>
      <div>
        <button
          className="py-2 px-4 text-lg font-semibold rounded-md bg-gray-800 text-white"
          onClick={togglePopover}
        >
          Switch Table
        </button>
      </div>
    </div>
    {isPopoverOpen && (
      <Switchtable
        close={() => {
          togglePopover();
        }}
        table={resData}
        handleClosePromptBYSwitch={handleClosePromptBYSwitch}
      />
    )}
  </>
);

const RoomList = ({ roomsData, activeRoom, handleRoomChange }) => (
  <div className="w-1/4 pr-4 mr-20">
    <div className="bg-gray-100 p-4 rounded-lg shadow-md">
      <h3 className="text-lg font-bold mb-4">Rooms</h3>
      {roomsData &&
        roomsData.map((room) => (
          <RoomCard
            key={room.roomName}
            room={room}
            onClick={handleRoomChange}
            activeRoom={activeRoom}
          />
        ))}
    </div>
  </div>
);

const Rooms = ({
  activeRoom,
  handleOpenPrompt,
  ordertables,
  printedKOT,
  printedKOTAll,
}) =>
  activeRoom &&
  activeRoom?.map((eachroom) => (
    <TableList
      tablesroom={eachroom}
      handleOpenPrompt={handleOpenPrompt}
      ordertables={ordertables}
      printedKOT={printedKOT}
      printedKOTAll={printedKOTAll}
    />
  ));

const TableList = ({
  tablesroom,
  handleOpenPrompt,
  ordertables,
  printedKOT,
  printedKOTAll,
}) => (
  <div className="w-full">
    <div className="relative flex items-center justify-center my-6">
      {/* Dotted Line */}
      <div className="border-t border-gray-400 border-dotted w-8"></div>
      <span className=" bg-white min-w-[100px] max-w-[300px]  px-4 text-gray-600 font-bold">
        {tablesroom?.roomName}
      </span>
      <div className="border-t border-gray-400 border-dotted w-full"></div>
      {/* Parcel Label */}
    </div>
    <div className="grid grid-cols-10  gap-4">
      {tablesroom &&
        tablesroom?.tableData.map((table) => (
          <TableCard
            key={table.tableNo}
            table={table}
            handleOpenPrompt={handleOpenPrompt}
            ordertables={ordertables}
            printedKOT={printedKOT}
            printedKOTAll={printedKOTAll}
            room={tablesroom.roomName}
          />
        ))}
    </div>
  </div>
);

const TabNavigation = ({ activeTab, setActiveTab, navigate }) => {
  const containerRef = useRef(null); // Ref for the App component's root element
  const [isFullscreen, setIsFullscreen] = useState(false); // State to track fullscreen status

  // Enter fullscreen
  const enterFullscreen = () => {
    const elem = containerRef.current;
    if (elem.requestFullscreen) {
      elem
        .requestFullscreen()
        .then(() => setIsFullscreen(true))
        .catch(console.error);
    } else if (elem.webkitRequestFullscreen) {
      // Safari
      elem.webkitRequestFullscreen();
      setIsFullscreen(true);
    } else if (elem.msRequestFullscreen) {
      // IE/Edge
      elem.msRequestFullscreen();
      setIsFullscreen(true);
    }
  };

  // Exit fullscreen
  const exitFullscreen = () => {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document
          .exitFullscreen()
          .then(() => setIsFullscreen(false))
          .catch(console.error);
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
        setIsFullscreen(false);
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
        setIsFullscreen(false);
      }
    }
  };

  // Toggle fullscreen
  const toggleFullscreen = () => {
    isFullscreen ? exitFullscreen() : enterFullscreen();
  };

  return (
    <div ref={containerRef} className="relative">
      <div className="flex border-b border-gray-300 mb-4">
        {/* Tab: Dine In */}
        <button
          className={`py-2 px-4 text-lg font-semibold ${
            activeTab === "dine-in"
              ? "border-b-2 border-yellow-500 text-gray-800"
              : "text-gray-600"
          }`}
          onClick={() => {
            setActiveTab("dine-in");
            navigate("/table-overview");
          }}
        >
          Dine In
        </button>

        {/* Tab: Parcel */}
        <button
          className={`py-2 px-4 text-lg font-semibold ${
            activeTab === "parcel"
              ? "border-b-2 border-yellow-500 text-gray-800"
              : "text-gray-600"
          }`}
          onClick={() => {
            setActiveTab("parcel");
            navigate("/order-parcel");
          }}
        >
          Parcel
        </button>

        {/* Fullscreen Button */}
        {/* <button
          className="ml-auto py-2 px-4 text-lg font-semibold text-gray-600 hover:text-gray-800"
          onClick={toggleFullscreen}
        >
          {isFullscreen ? "Exit Fullscreen" : "Fullscreen"}
        </button> */}
      </div>
    </div>
  );
};

const TableOverview = () => {
  const { getDigitalOrders } = useCloud();
  const { getAllCompletedOrders, flushAllCompleteOrders, processOrder } =
    useOrder();

  useEffect(() => {
    const handleServiceWorkerMessage = async (event) => {
      console.log(
        "Received message from service worker:",
        event.data.notification.title
      );
      if (event.data.notification.title === "NOTIFICATION_RECEIVED") {
        try {
          playRing();
        } catch (e) {
          console.log(e);
        }
        const orderEvent = new CustomEvent("DIGITAL_ORDER_RECEIVED", {});
        window.dispatchEvent(orderEvent);
      }
    };

    // Add event listener for service worker message
    navigator.serviceWorker.addEventListener(
      "message",
      handleServiceWorkerMessage
    );

    // Cleanup function to remove the event listener
    return () => {
      navigator.serviceWorker.removeEventListener(
        "message",
        handleServiceWorkerMessage
      );
    };
  }, []); // Empty dependency array ensures this effect runs only once, on mount

  // Second useEffect to handle DIGITAL_ORDER_RECEIVED event
  useEffect(() => {
    const fetchDigitalOrder = async () => {
      const { event, requestKOTAmoutntItem, requestKot } =
        await getDigitalOrders();
      if (event) {
        // You can add some condition to check if the order has already been processed
        requestKOTAmoutntItem.forEach(async (order) => {
          const formatedOrder = order.items.map((newOrder) => ({
            itemId: newOrder.itemId,
            quantity: newOrder.quantity,
            varientid: newOrder.varientid || undefined,
          }));
          const rawOrder = order.items.map((newOrder) => ({
            id: newOrder.itemId,
            name: newOrder.name,
            price: newOrder.price || "0",
            variant: newOrder.variant || [],
            qty: newOrder.quantity,
          }));
          const response = await processOrder({
            rawOrder,
            formatedOrder,
            tableVPA: order.tableVPA,
            cancleOrders: [],
            extraOrders: [],
            username: order.name,
            usermobile: order?.mobileNo || "NA",
            orderStatus: "RequestKOT",
            onlineBillNo: order.onlineCode,
          });
        });
        const digitalOrdersTables = requestKot.map((table) => table.tableVPA);
        store.setDigitalOrderVPA(digitalOrdersTables);
      }
    };

    // Function to call fetchDigitalOrder on event
    const handleDigitalOrderReceived = () => {
      fetchDigitalOrder();
    };

    // Add event listener for DIGITAL_ORDER_RECEIVED
    window.addEventListener(
      "DIGITAL_ORDER_RECEIVED",
      handleDigitalOrderReceived
    );

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener(
        "DIGITAL_ORDER_RECEIVED",
        handleDigitalOrderReceived
      );
    };
  }, []); // Empty dependency array ensures this effect runs only once, on mount

  const { syncCloud } = useCloud();
  const [activeRoom, setActiveRoom] = useState([]);
  const [tablesroom, setTablesRoom] = useState([]);
  const [showPrompt, setShowPrompt] = useState(false);
  const [clickedTable, setClickedTable] = useState(null);
  const [ordertables, setOrdersTable] = useState([]);
  const [printedKOT, setPrintedKOT] = useState([]);
  const [printedKOTAll, setPrintedKOTAll] = useState([]);
  const [address, setAddress] = useState();
  const [selectedPrintedKOTAll, setselectedPrintedKOTAll] = useState([]);
  const [requestKOTAll, setrequestKOTAll] = useState([]);
  const [selectedrequestKOTAll, setselectedrequestKOTAll] = useState([]);
  const [tableStatuses, setTableStatuses] = useState({});
  const [roomsData, setRoomsData] = useState([]);
  const [activeTab, setActiveTab] = useState("dine-in");
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [cloudSync, setCloudSync] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // FOR FORCEFULL RE-RENDER
  const navigate = useNavigate();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const handleOpenPrompt = async (id, qrpidVPA, room) => {
    console.log(room);

    setClickedTable({ id, qrpidVPA, room });
    const totalPr = printedKOTAll?.reduce((acc, ele) => {
      if (ele.tableVPA === qrpidVPA) {
        acc.push(ele);
      }
      return acc;
    }, []);
    setselectedPrintedKOTAll(totalPr);

    const kot = requestKOTAll?.reduce((acc, ele) => {
      if (ele.tableVPA === qrpidVPA) {
        acc.push(ele);
      }
      return acc;
    }, []);
    setselectedrequestKOTAll(kot);
    setShowPrompt(true);
  };

  const handleClosePrompt = () => {
    // setShowPrompt(true);
    setActiveTab("parcel");
    // navigate("/table-overview");
    navigate("/order-parcel");
    // const rooms = store.rooms;
    setTimeout(() => {
      setActiveTab("dine-in");
      navigate("/table-overview");
    }, 10); // 1-second delay

    //   setActiveTab("parcel")
    //   // navigate("/table-overview");
    //   navigate("/order-parcel");
    //   setTimeout(() => {
    //     setActiveTab("dine-in");
    //     navigate("/table-overview");
    // }, 100); // 1-second delay

    // setTablesRoom(rooms);

    setShowPrompt(false);
  };

  const handleClosePromptBYSwitch = () => {
    // setShowPrompt(true);
    navigate("/report");
    setTimeout(() => {
      setActiveTab("parcel");
      // navigate("/table-overview");
      navigate("/order-parcel");
    }, 10);

    // const rooms = store.rooms;
    setTimeout(() => {
      setActiveTab("dine-in");
      navigate("/table-overview");
    }, 100); // 1-second delay

    //   setActiveTab("parcel")
    //   // navigate("/table-overview");
    //   navigate("/order-parcel");
    //   setTimeout(() => {
    //     setActiveTab("dine-in");
    //     navigate("/table-overview");
    // }, 100); // 1-second delay

    // setTablesRoom(rooms);

    setShowPrompt(false);
  };

  useEffect(() => {
    setAddress({
      name: localStorage.getItem("name"),
      address: localStorage.getItem("address"),
    });
  }, [store.reload]);

  useEffect(() => {
    const rooms = store.rooms;
    if (rooms && rooms.length > 0) {
      setRoomsData(rooms);
      setActiveRoom(rooms);
      setTablesRoom(rooms);
    }
  }, [store.rooms.length, store.reload]);

  useEffect(() => {
    const printedKOTs = store.printedKOTs;
    if (printedKOTs && printedKOTs.length > 0) {
      setPrintedKOTAll(printedKOTs);
    } else {
      setPrintedKOTAll([]);
    }

    // update printed bill
    if (selectedPrintedKOTAll.length > 0) {
      const tableVPA = selectedPrintedKOTAll[0].tableVPA;
      const totalPr = printedKOTAll?.reduce((acc, ele) => {
        if (ele.tableVPA === tableVPA) {
          acc.push(ele);
        }
        return acc;
      }, []);
      setselectedPrintedKOTAll(totalPr);
    }
  }, [store.printedKOTs.length, store.totalBillAmount, store.reload]);

  useEffect(() => {
    const printedKOT_VPA = store.printedKOT_VPA;
    if (printedKOT_VPA && printedKOT_VPA.length > 0) {
      const printedKOT = printedKOT_VPA.map((ele) => ele.tableVPA);
      setPrintedKOT(printedKOT);
    } else {
      setPrintedKOT([]);
    }
  }, [store.printedKOT_VPA.length, store.reload]);

  useEffect(() => {
    setTimeout(() => {
      console.log(navigator.onLine);
      setIsOnline(navigator.onLine);
    }, 1000);
  }, [navigator.onLine]);

  useEffect(() => {
    setOrdersTable(store.digitalOrderVPAs);
  }, [store.digitalOrderVPAs.length, showPrompt, store.reload]);

  // useEffect(() => {
  //   const fetchTables = async () => {
  //     try {
  //       const token = localStorage.getItem("token");
  //       const requestKOT = await RequestedKOTData(token);
  //       setrequestKOTAll(requestKOT.data.requestKOTAmoutntItem);

  //       const setRequestKOT = [];
  //       requestKOT.data.requestKot.forEach((ele) => {
  //         setRequestKOT.push(ele.tableVPA);
  //       });
  //       setOrdersTable(setRequestKOT);

  //       const datas = await GetTableData(token);
  //       setPrintedKOTAll(datas.data.kotPrintedItem);

  //       const printedKOT = [];
  //       datas.data.vpaKotPrinted.forEach((ele) => {
  //         printedKOT.push(ele.tableVPA);
  //       });
  //       setPrintedKOT(printedKOT);

  //       setAddress({
  //         name: localStorage.getItem("name"),
  //         address: localStorage.getItem("address"),
  //       });
  //       const fetch = async () => {
  //         const key = await requestFcmToken();
  //         console.log(key, "...............");
  //       };
  //       fetch();
  //     } catch (error) {
  //       console.error("Failed to fetch tables", error);
  //     }
  //   };

  //   fetchTables();
  // }, []);

  // const handleRoomChange = (room) => {
  //   setActiveRoom(room.roomName);
  //   setTablesRoom(room.tableData);
  // };

  const handleKOTGenerate = () => {
    if (clickedTable) {
      setTableStatuses((prevStatuses) => ({
        ...prevStatuses,
        [clickedTable]: "printed",
      }));
    }
  };

  const handleBillGenerate = () => {
    if (clickedTable) {
      setTableStatuses((prevStatuses) => ({
        ...prevStatuses,
        [clickedTable]: "blank",
      }));
    }
  };

  const handleCloudSync = async () => {
    setCloudSync(true);

    try {
      const orders = await getAllCompletedOrders();
      const response = await syncCloud(orders);
      if (response) {
        await flushAllCompleteOrders();
        // localStorage.setItem("lastBillNo",parseInt(localStorage.getItem("lastBillNo"))+parseInt(orders.length))
      }
      setCloudSync(false);
    } catch (err) {
      console.log(err);
      setCloudSync(false);
    }
  };

  return (
    <>
      {cloudSync && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="flex flex-col items-center">
            <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32 mb-4"></div>
            <span className="text-white text-xl">Cloud Sync in progress !</span>
          </div>
        </div>
      )}
      {isOnline && (
        <button
          className="fixed bottom-4 right-4 bg-blue-500 text-white py-2 px-4 rounded-full flex items-center shadow-lg"
          onClick={handleCloudSync}
        >
          <span className="mr-2">Sync Cloud</span>
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 15a4 4 0 01.88-7.906A5.5 5.5 0 0112 3.5a5.5 5.5 0 014.12 9.594A4 4 0 1115 19H6a4 4 0 01-3-4z"
            ></path>
          </svg>
        </button>
      )}
      <Header1 />

      <TabNavigation
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        navigate={navigate}
      />
      {!showPrompt && (
        <div className="bg-white shadow-lg rounded-lg p-6 mt-6">
          <Legend
            isPopoverOpen={isPopoverOpen}
            togglePopover={togglePopover}
            resData={roomsData}
            handleClosePromptBYSwitch={handleClosePromptBYSwitch}
          />
          <div className="w-full">
            {activeRoom && (
              <Rooms
                activeRoom={activeRoom}
                handleOpenPrompt={handleOpenPrompt}
                ordertables={ordertables}
                printedKOT={printedKOT}
                printedKOTAll={printedKOTAll}
              />
            )}

            {/* <TableList
              tablesroom={tablesroom}
              handleOpenPrompt={handleOpenPrompt}
              ordertables={ordertables}
              printedKOT={printedKOT}
              printedKOTAll={printedKOTAll}
            /> */}
          </div>
        </div>
      )}

      {showPrompt && (
        <AcceptOrder
          handleClosePrompt={handleClosePrompt}
          setLoading={setIsLoading}
          data={{
            table: clickedTable.id,
            tableVPA: clickedTable.qrpidVPA,
            room: clickedTable.room,
          }}
          printed={selectedPrintedKOTAll}
          address={address}
          onKOTGenerate={handleKOTGenerate}
          onBillGenerate={handleBillGenerate}
        />
      )}
    </>
  );
};

export default observer(TableOverview);
