import React, { useEffect, useRef, useState } from "react";
import {
  changeKOTStatus,
  changeQuentityOfItem,
  extraAmountAdded,
  orderReject,
  paymentMethodAPI,
} from "./Service/KotStatusChange.service";
import AddItemComponent from "./AddNewItem.resta";
import PaymentPopup from "../PaymentMethod/PaymentMethod";
import { printKOT } from "../Print/PrintKOT";
import store from "../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import useOrder from "../../hooks/useOrder";
import { useCloud } from "../../hooks/useCloud";
import { cancleAndUpdate } from "../Print/CancleAndUpdateKOT";

// BillToPrint component with forwardRef to allow ref forwarding
// const BillToPrint = React.forwardRef((props, ref) => (
//   <div ref={ref} className="p-4 bg-white">
//     <h2 className="text-xl font-bold mb-4">Bill</h2>
//     <p>Name: Rajat</p>
//     <p>Items: MOMOS - 200 x 2</p>
//     <p>Total: 400</p>
//     <p className="mt-4">Thank you for your order!</p>
//   </div>
// ));

// KOTSection component
const KOTSection = ({
  name,
  items,
  orderId,
  oldOrder,
  Amount,
  roomData,
  hotelData,
  billNo,
  DoneOrderBYbillNo,
  vpa,
  setLoading,
  handleClosePrompt
}) => {
  // console.log(billNo,"...........");
  const { acceptOnlineOrder, rejectOnlineOrder, getOnlineOrderBillNo, plusMinusByBillNo } = useOrder()
  const { changeKOTStatus, orderReject } = useCloud()
  const [redItems, setRedItems] = useState(items);
  const [totalAmount, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [greenItems, setGreenItems] = useState(oldOrder);
  const [showForm, setShowForm] = useState(false);
  const [itemName, setItemName] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const [extraAmoutn, setExtraAmount] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [payment, setPaymetMethod] = useState(false);
  const [changeQuentity, setChangeQuentity] = useState([]);
  const [removeQuentity, setRemoveQuentity] = useState([]);
  const[reloadTempSolution , setReloadTempSolution] =useState();

  // console.log(items);

  const printRef = useRef();
  // useEffect(()=>{
  //   setRedItems(items)

  // },[])

  const printKOTTemp = (type) => {
    const printContent = printRef.current;
    const date = new Date();
    const day = date.getDate(); // Day of the month (1-31)
    const month = date.getMonth() + 1; // Month (0-11, so we add 1 to get 1-12)
    const year = date.getFullYear();
    const time = date.toLocaleTimeString();

    // const totalBills =
    const KOT = `
      <div style="padding: 0; margin:0; background-color: white; font-family: Arial, sans-serif; width: 100%; max-width: 300px;">
        <h2 style="text-align: center; font-size: 1rem; font-weight: bold; margin-bottom: 1rem;">${type}</h2>
       
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
        <p style="font-size: 0.7rem; margin: 0.4rem 0;"><strong>${`${day} / ${month} / ${year} : ${time}`}</strong> </p>
        <p style="font-size: 0.9rem; margin: 0.5rem 0;"><strong>Table:</strong> ${roomData.table
      }/${roomData.room} <strong>   Bill No:</strong> </p>
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
        <div>
          <table style="width: 100%; font-size: 0.8rem; border-collapse: collapse;">
            <thead>
              <tr>
                <th style="text-align: left;">Item</th>
                <th style="text-align: right;">Qty.</th>
               
              </tr>
            </thead>
            <tbody>
              ${redItems
        .map(
          (item) => `
                <tr>
                  <td style="padding: 0.25rem 0;"><strong>${item.name}</strong></td>
                  <td style="text-align: right;">${item.quantity}</td>
                </tr>
              `
        )
        .join("")}
            </tbody>
          </table>
        </div>
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
        <p style="text-align: center; font-size: 0.7rem; margin: 0.5rem 0;">Thank you for visiting!</p>
      </div>
    `;
    const WinPrint = window.open("", "", "width=800,height=600");
    WinPrint.document.write(KOT);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    return WinPrint.close();
  };

  const handleAddItemClick = () => {
    setShowForm(true);
  };

  const handleSaveItem = () => {
    setGreenItems([
      ...greenItems,
      { name: itemName, price: itemPrice, quantity: quantity },
    ]);
    setItemName("");
    // let amoutn = extraAmoutn+itemPrice*
    setExtraAmount(parseInt(itemPrice) * parseInt(quantity));

    setItemPrice("");
    setShowForm(false);
    // name, amount, quantity, billNo
    extraAmountAdded({
      name: itemName,
      amount: itemPrice,
      quantity: quantity,
      billNo: billNo,
    });
    // call one api for save all extra amount  on billNo
  };

  const handlePrintKOT = async () => {
    try {
      const onlineCode = await getOnlineOrderBillNo(vpa);
      console.log(onlineCode,"..");
      
      const response = await changeKOTStatus({
        billNo: onlineCode
      })
      console.log(onlineCode,"..");

      if (response.event) {
        const itemToMove = redItems;
        setGreenItems([...greenItems, ...itemToMove]);
        setRedItems([]);
        const total = subTotal + parseInt(itemToMove.price);
        setSubTotal(total);
        setTotal(total);
        store.removeDigitalOrderVPA(vpa)
        await acceptOnlineOrder(vpa)
        printKOTTemp("Check KOT");
        console.log(onlineCode,"..");

      }
    } catch (e) {
      alert("Please refresh software and try again, please!");
    }
  };

  const rejectOrder = async () => {

    try {
      const onlineCode = await getOnlineOrderBillNo(vpa)
      const response = await orderReject({
        billNo: onlineCode,
        items: [redItems]
      })

      if (response.event) {
        await rejectOnlineOrder(vpa)
        setRedItems([]);
        alert("Order Rejected! ");
      }
    } catch (e) {
      alert("Please refresh software and try again, please!");
    }
  };

  useEffect(() => {
    let totalAmount = 0;
    greenItems.map((ele) => {
      const thisele = parseInt(ele.price) * parseInt(ele.quantity);
      totalAmount = parseInt(totalAmount) + thisele;
    });
    setSubTotal(totalAmount);
    setTotal(totalAmount);
  }, [greenItems]);

  const handleComplete = () => {
    // billNo
    setPaymetMethod(true);
    // DoneOrderBYbillNo(billNo)
  };
  const ReprintKothandle = () => {
    printKOT("Duplicate KOT", greenItems, roomData, []);
  };

  const handlePaymentComplete = async (paymentData) => {
    console.log("Payment Data:", paymentData);
    const methods = [];
    if (paymentData.method == "partial") {
      methods.push({
        name: "cash",
        amount: paymentData.cashAmount,
      });
      methods.push({
        name: "online",
        amount: paymentData.onlineAmount,
      });
    } else {
      methods.push({
        name: paymentData.method,
        amount: "",
      });
    }
    console.log("......", methods, billNo);

    const data = await paymentMethodAPI({ billNo, methods });
    console.log(data, "...........");
    if (data.data?.event) {
      setPaymetMethod(true);
      DoneOrderBYbillNo(billNo);
    }

    // Handle payment completion (e.g., API call, save to state, etc.)
  };
  const addItemOrUpdateQuantity = (item, action) => {
    // console.log(item, ".......", action);

    // Find the item in the changeQuentity array by both id and name
    const existingItem = changeQuentity.find(
      (i) => i.id === item.id && i.name === item.name
    );

    if (existingItem) {
      // If the item exists, update its quantity based on the action
      const updatedItems = changeQuentity.map((i) => {
        if (i.id === item.id && i.name === item.name) {
          const updatedQuantity =
            action === '+'
              ? parseInt(i.quantity) + 1 // Increment
              : parseInt(i.quantity) - 1; // Decrement
          console.log(updatedQuantity);


          return { ...i, quantity: updatedQuantity }; // Ensure quantity doesn't go below 0
        }
        return i;
      });

      // Filter out items with quantity 0 (optional)
      setChangeQuentity(updatedItems.filter((i) => i.quantity > 0 || i.quantity < 0));
    } else if (action === '+') {
      // If the item does not exist and the action is '+', add it with quantity 1
      setChangeQuentity([...changeQuentity, { ...item, quantity: 1 }]);
    } else if (action === '-') {
      setChangeQuentity([...changeQuentity, { ...item, quantity: -1 }]);
    }

    console.log(changeQuentity, "....");
  };


  const changeExtraAmountQuentity = (item) => {
    // Find the item in the changeQuentity array by both id and name
    console.log(item, ".......");
  };

  // const removeQuentityItem = (item) => {
  //   const existingItem = removeQuentity.find((i) => i.id === item.id);

  //   if (existingItem) {
  //     // If item exists, increase its quantity
  //     const updatedItems = removeQuentity.map((i) =>
  //       i.id === item.id
  //         ? parseInt(item.quantity) - parseInt(1) > i.quantity
  //           ? { ...i, quantity: parseInt(i.quantity) + 1 }
  //           : i // Increment the quantity
  //         : i
  //     );

  //     setRemoveQuentity(updatedItems);
  //   } else {
  //     // If item does not exist, add it to the array
  //     setRemoveQuentity([...removeQuentity, { ...item, quantity: 1 }]);
  //   }
  // };
  const removeQuantityItem = (item) => {
    const existingItem = changeQuentity.find(
      (i) => i.id === item.id && i.name === item.name
    );

    if (existingItem && parseInt(existingItem.quantity) > 1) {
      // Decrease the quantity if it's greater than 1
      const updatedItems = changeQuentity.map((i) =>
        i.id === item.id && i.name === item.name
          ? { ...i, quantity: parseInt(i.quantity) - 1 }
          : i
      );
      setChangeQuentity(updatedItems);
    } else if (existingItem && parseInt(existingItem.quantity) === 1) {
      // Remove the item if the quantity is 1
      const updatedItems = changeQuentity.filter(
        (i) => !(i.id === item.id && i.name === item.name)
      );
      setChangeQuentity(updatedItems);
    }
  };

  const saveAndPrintKKOT = async (type) => {
    console.log(setLoading);
    
    setLoading(true)
    const ans = await plusMinusByBillNo({ tableVPA: vpa, billNo: billNo, items: changeQuentity });
  
    if (type == "print" && ans) {

      const added = changeQuentity.filter((ele) => ele.quantity > 0);
      const removed = changeQuentity.filter((ele) => ele.quantity < 0);
      cancleAndUpdate("Updated KOT", added, roomData, removed, []);

      setChangeQuentity([]);
      handleClosePrompt();
      // setLoading(false)
      // alert("Updated ")
    } else if (!ans) {
      alert("Not Saved");
      handleClosePrompt()
      setLoading(false)

      //only Save 
    } else {
      setChangeQuentity([])
      handleClosePrompt()
      setLoading(false)

    }

    // /order/change/quentity
    // const items = await changeQuentityOfItem({
    //   billNo: billNo,
    //   items: changeQuentity,
    // });
    // if (items?.data?.event) {
    //   setChangeQuentity([])
    //   window.location.reload();
    // } else {
    //   alert("Something Wrong!");
    // }
  };

  // const cancleQuentity
  const SaveOnlyQu = async () => {
    const items = await changeQuentityOfItem({
      billNo: billNo,
      items: changeQuentity,
    });
    if (items?.data?.event) {
      // printKOT("Updated KOT",changeQuentity,roomData,[])
      window.location.reload();
    } else {
      alert("Something Wrong!");
    }
  };
  const plusMinus = async (type, item) => {
    console.log(item, type, billNo);


  }
 const handleTempSolution=()=>{
    handleClosePrompt()
  }


  return (
    <div className="bg-white shadow rounded p-4 mb-4">
      <div className="flex justify-between">
        <h4 className="text-lg font-semibold mb-2">Name: {name}</h4>
        <h4 className="text-lg font-semibold mb-2">BillNo :{billNo}</h4>
        <div>.</div>
      </div>
      {reloadTempSolution && <><div onClick={handleTempSolution}>*</div></>
        
      }

      {/* Green Section (Items after Print KOT) */}
      {greenItems &&
        greenItems?.map((item, index) => (
          <div
            key={index}
            className="flex items-center justify-between bg-green-100 p-3 rounded mb-2"
          >
            {/* Name Section */}
            <div className="flex-1">
              <span>{item.name}</span>
            </div>

            {/* Quantity Section */}

            <div className=" ml-5 flex-1">

              {parseInt(item.quantity) + parseInt(changeQuentity.find(
                (i) => i.id === item.id && i.name === item.name
              )?.quantity ? changeQuentity.find(
                (i) => i.id === item.id && i.name === item.name
              )?.quantity : 0) > 0 ? <span className=" cursor-pointer" onClick={() => addItemOrUpdateQuantity(item, "-")}><strong>-</strong></span> : ""
              }
              <span className="pl-5">Q: {parseInt(item.quantity) + parseInt(changeQuentity.find(
                (i) => i.id === item.id && i.name === item.name
              )?.quantity ? changeQuentity.find(
                (i) => i.id === item.id && i.name === item.name
              )?.quantity : 0)
              }</span>
              <span className=" cursor-pointer ml-5" onClick={() => addItemOrUpdateQuantity(item, "+")}><strong>+</strong></span>
            </div>

            {/* Price Section */}
            <div className="flex-1 text-right">
              <span className="text-lg font-semibold">
                {item.price ? item.price : item.amount}
              </span>
            </div>

            {/* Total Calculation Section */}
            <div className="flex-1 text-right">
              <span>
                {" "}
                {parseFloat(item.quantity) *
                  parseFloat(item.price ? item.price : item.amount)}
              </span>
            </div>
          </div>
        ))}
      {greenItems && (
        <div className=" flex justify-end mb-5">
          {/* <strong>Total Amount : {totalAmount}</strong> */}
        </div>
      )}
      {greenItems?.length > 0 && (
        <div className="flex justify-end gap-10">
          {changeQuentity.length > 0 && <>
            <button className="bg-blue-400 p-2 rounded" onClick={() => saveAndPrintKKOT("print")}>
              Save & Print
            </button>
            <button className="bg-blue-400 p-2 rounded" onClick={() => saveAndPrintKKOT("save")}>
              Save
            </button>
          </>}
          <button
            className="bg-blue-400 p-2 rounded"
            onClick={ReprintKothandle}
          >
            Reprint KOT
          </button>
        </div>
      )}

      {payment && (
        <PaymentPopup
          onComplete={handlePaymentComplete}
          cancle={() => {
            setPaymetMethod(false);
          }}
        />
      )}

      {/* Red Section (Original Items) */}
      <div className="mt-10">
        {redItems &&
          redItems.map((item, index) => (
            <div
              key={index}
              className="relative flex items-center justify-between bg-red-100 p-3 rounded group mb-2"
            >
              <div className="flex items-center ">
                <span>{item.name}</span>
                <span className="pl-20">Q:{item.quantity}</span>
              </div>
              <span className="text-lg font-semibold">{item.price}</span>
              {/* Print KOT button */}
            </div>
          ))}
      </div>
      {redItems.length > 0 && (
        <div className="flex justify-between">
          <button
            className=" top-3 bg-blue-500 text-white px-3 py-1 rounded "
            onClick={rejectOrder}
          >
            Reject Order
          </button>
          <button
            className=" top-3 bg-blue-500 text-white px-3 py-1 rounded "
            onClick={handlePrintKOT}
          >
            Print KOT
          </button>
        </div>
      )}

      {/* Form to add new item */}
      {showForm && (
        <AddItemComponent roodata={roomData} billNo={billNo} vpa={vpa} />
      )}
    </div>
  );
};

export default KOTSection;
