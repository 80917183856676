import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useCloud } from "../../../hooks/useCloud";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Sales data for 100 products

// Generate last 30 days dynamically
const getLast30Days = () => {
  const dates = [];
  const today = new Date();
  for (let i = 1; i <= 30; i++) {
    const day = new Date(today);
    day.setDate(today.getDate() - i);
    dates.push(day.toISOString().split("T")[0]); // Format: YYYY-MM-DD
  }
  return dates.reverse();
};
const ItemWiseSell = () => {
    const { itemWiseSale } = useCloud();
    const [salesData, setSalesData] = useState({});
    const [dates, setDates] = useState(getLast30Days());
    const [selectedProduct, setSelectedProduct] = useState("");
  
    // Fetch sales data from API
    useEffect(() => {
      const fetchData = async () => {
        try {
          const item = await itemWiseSale();
          console.log(item,"....");
          if (item.event) {
            setSalesData(item.data);
            
            setSelectedProduct(Object.keys(item.data)[0] || ""); // Default to first product
          } else {
            console.error("Failed to fetch data:", item);
          }
        } catch (error) {
          console.error("Error fetching sales data:", error);
        }
      };
  
      fetchData();
    }, []);
  
    const handleChange = (event) => {
      setSelectedProduct(event.target.value);
    };
  
    const data = {
      labels: dates,
      datasets: [
        {
          label: `${selectedProduct} Sales`,
          data: salesData[selectedProduct] || Array(30).fill(0), // Use empty array if no data
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
      ],
    };
  
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: `Sales Data for ${selectedProduct}`,
        },
      },
    };
  
    return (
      <div className="mb-6 w-90% h-80vh">
        <div className="mb-6 text-center">
          <label className="mr-2 text-lg font-medium">Select Product:</label>
          <select
            className="p-2 border rounded-md"
            value={selectedProduct}
            onChange={handleChange}
          >
            {Object.keys(salesData).map((product) => (
              <option key={product} value={product}>
                {product}
              </option>
            ))}
          </select>
        </div>
        <div className="bg-white p-4 shadow-md rounded-lg">
          <Line data={data} options={options} />
        </div>
      </div>
    );
  };
export default ItemWiseSell;
