import { api } from "../../../Environments/Environment.Test";

const authUrl = `${api}/restaurant`;

export function changeKOTStatus(requestBody) {
  // const navigate = useNavigate();

  return new Promise(async (resolve) => {
    try {
      const token = await localStorage.getItem("authToken");
      const response = await fetch(`${authUrl}/afterKotPrintchange/Status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      resolve({ error: error.message });
    }
  });
}

export function orderDone(requestBody) {
  // const navigate = useNavigate();

  return new Promise(async (resolve) => {
    try {
      const token = await localStorage.getItem("authToken");
      const response = await fetch(`${authUrl}/order/done`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      resolve({ error: error.message });
    }
  });
}
// /order/create/restarunt

export function createOrderByRestarunt(requestBody) {
    // const navigate = useNavigate();
  
    return new Promise(async (resolve) => {
      try {
        const token = await localStorage.getItem("authToken");
        const response = await fetch(`${authUrl}/order/create/restarunt`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        resolve({ data });
      } catch (error) {
        resolve({ error: error.message });
      }
    });
  }

  export function extraAmountAdded(requestBody) {
    // const navigate = useNavigate();
  
    return new Promise(async (resolve) => {
      try {
        const token = await localStorage.getItem("authToken");
        const response = await fetch(`${authUrl}/order/addExtracharge`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        resolve({ data });
      } catch (error) {
        resolve({ error: error.message });
      }
    });
  }
// /order/update/addnewitem

export function editExistingOrder(requestBody) {
  // const navigate = useNavigate();

  return new Promise(async (resolve) => {
    try {
      const token = await localStorage.getItem("authToken");
      const response = await fetch(`${authUrl}/order/update/addnewitem`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      resolve({ error: error.message });
    }
  });
}

export function paymentMethodAPI(requestBody) {
  // const navigate = useNavigate();

  return new Promise(async (resolve) => {
    try {
      const token = await localStorage.getItem("authToken");
      const response = await fetch(`${authUrl}/order/done/paymentmethod`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      resolve({ error: error.message });
    }
  });
}

export function orderReject(requestBody) {
  // const navigate = useNavigate();

  return new Promise(async (resolve) => {
    try {
      const token = await localStorage.getItem("authToken");
      const response = await fetch(`${authUrl}/order/cancle/reject/digitalOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      resolve({ error: error.message });
    }
  });
}

export function changeQuentityOfItem(requestBody) {
  // const navigate = useNavigate();

  return new Promise(async (resolve) => {
    try {
      const token = await localStorage.getItem("authToken");
      const response = await fetch(`${authUrl}/order/change/quentity`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      resolve({ error: error.message });
    }
  });
}


export function getPublicItemBYVPA(requestBody) {
  // const navigate = useNavigate();

  return new Promise(async (resolve) => {
    try {
      const token = await localStorage.getItem("authToken");
      const response = await fetch(`${api}/public/user/Item?vpa=${requestBody.vpa}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      resolve({ error: error.message });
    }
  });
}

export function getWaiterList() {
  // const navigate = useNavigate();

  return new Promise(async (resolve) => {
    try {
      const token = await localStorage.getItem("authToken");
      const response = await fetch(`${api}/restaurant/waiter/allwaiter`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      resolve({ error: error.message });
    }
  });
}

export function getAllCategoryAndItem() {
  // const navigate = useNavigate();

  return new Promise(async (resolve) => {
    try {
      const token = await localStorage.getItem("authToken");
      const response = await fetch(`${authUrl}/category/menu/varient/getfororder`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      resolve({ error: error.message });
    }
  });
}