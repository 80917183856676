
import { getMessaging, getToken } from "@firebase/messaging";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LogInAdminRes } from "./AuthGard.Service";
import { requestFcmToken } from "../../utils/firebaseUtils";
import login from "../../img/login.svg";
// import io from 'socket.io-client'; // Import socket.io-client

// const socket = io('http://localhost:3000'); // Replace with your server URL

const LoginNew = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Listen for the 'adminLogin' event from the server
    // socket.on('adminLogin', (data) => {
    //   alert(Admin logged in: ${data.name});
    //   console.log('Admin login event received:', data);
    // });
    // Clean up the socket connection when the component unmounts
    // return () => {
    //   socket.off('adminLogin');
    // };
  }, []);

  const handleLogin = async () => {
    const brkey = await requestFcmToken();

    const { data } = await LogInAdminRes({ username, password, key: brkey });

    if (data.token) {
      localStorage.setItem("authToken", data.token);
      localStorage.setItem("address", data.address);
      localStorage.setItem("name", data.name);
      localStorage.setItem("email", username);
      localStorage.setItem("lastBillNo", data.lastbillNo);
      localStorage.setItem("canclepassword", data.canclepassword);

      navigate("/table-overview");
    } else {
      setError("Invalid credentials");
    }
  };

  const handleSignUp = () => {
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Please enter a valid email address.");
    } else if (password.length < 6) {
      setError("Password must be at least 6 characters long.");
    } else {
      setError("");
      alert("Sign up successful! Please log in.");
      setIsSignUp(false);
      setUsername("");
      setPassword("");
      setEmail("");
    }
  };

  return (
    <div className="flex h-screen">
      {/* Left Section: Illustration */}
      <div className="flex w-1/4 items-center justify-center bg-gradient-to-b from-[#16A34A] to-[#1B8B45]">
        <div
          className="relative w-[300px] h-[400px] bg-gradient-to-b from-[#3DB067CC] to-[#96E2B2CC] rounded-2xl shadow-lg backdrop-blur-[4px]"
          style={{
            boxShadow: "0px 6px 10px 0px #1F8043",
          }}
        >
          <img
            src={login}
            alt="Cart Illustration"
            className="absolute inset-0 m-auto w-[100%] h-auto"
          />
        </div>
      </div>

      {/* Right Section: Form */}
      <div className="flex w-3/4 items-center justify-center bg-white">
        <div className="w-full max-w-sm px-8 py-6">
          <h2
            className="text-2xl font-bold mb-4 text-gray-800"
            style={{ textDecoration: "underline" }}
          >
            Log in
          </h2>
          <p className="mb-6 text-gray-600">
            New to QRapid?{" "}
            <a href="/signup" className="text-green-600 underline">
              Create new account
            </a>
          </p>

          {error && <p className="text-red-500 mb-4">{error}</p>}

          {isSignUp && (
            <input
              type="email"
              placeholder="Email"
              className="border p-2 mb-4 w-full"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          )}

          <div className="mb-4">
            <label
              htmlFor="username"
              className="block text-sm font-medium text-gray-700"
            >
              Username
            </label>
            <input
              id="username"
              type="text"
              placeholder="Enter your username"
              className="mt-1 w-full p-2 border border-gray-300 rounded"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              id="password"
              type="password"
              placeholder="Enter your password"
              className="mt-1 w-full p-2 border border-gray-300 rounded"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center">
              <input
                id="remember-me"
                type="checkbox"
                className="h-4 w-4 text-green-600 border-gray-300 rounded"
              />
              <label
                htmlFor="remember-me"
                className="ml-2 block text-sm text-gray-800"
              >
                Save account
              </label>
            </div>
            <a
              href="/forgot-password"
              className="text-sm text-green-600 hover:underline"
            >
              Forgot Password?
            </a>
          </div>

          {isSignUp ? (
            <button
              onClick={handleSignUp}
              className="bg-blue-500 text-white p-2 rounded w-full"
            >
              Sign Up
            </button>
          ) : (
            <button
              onClick={handleLogin}
              className="w-full bg-green-600 text-white p-2 rounded-full hover:bg-green-700"
            >
              Login
            </button>
          )}
          {/* <Link to="/signup">
          <p className="mt-4 text-blue-500 cursor-pointer">
            If you are a new restaurant owner, please sign up with us
          </p>
        </Link> */}
        </div>
      </div>
    </div>
  );
};

export default LoginNew;