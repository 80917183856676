import { useState, useEffect } from "react";
import store from "../store/index";

const IN_PROCESS_ORDERS_STORE_NAME = "InProcessOrders";
const COMPLETE_ORDERS_STORE_NAME = "completedOrders";
const ORDER_HISTORY_STORE_NAME = "orderHistory";

const useOrder = () => {
  const [orders, setOrders] = useState([]);
  const { dbInstance: db } = store;

  useEffect(() => {
    const fetchOrders = async () => {
      const allOrders = await getAllOrders();
      const allKOTs = [];
      const allKOTsVPA = [];
      let completeOrdersBillNo = await getHighestBillNoFromCompletedOrders();
      if (completeOrdersBillNo === 0) {
        completeOrdersBillNo = Number(localStorage.getItem("lastBillNo")) || 0;
      }
      const currentGoingBillNo = allOrders.reduce((acc, order) => {
        const { printKOT } = order;
        allKOTs.push(printKOT);
        allKOTsVPA.push({ tableVPA: order.tableVPA });
        if (order.billNo > acc) {
          return order.billNo;
        }
        return acc;
      }, 0);

      const lastBillNo =
        completeOrdersBillNo > currentGoingBillNo
          ? completeOrdersBillNo
          : currentGoingBillNo;
      store.setLastBillNo(lastBillNo);
      store.addPrintedKOTs(allKOTs);
      store.addPrintedKOT_VPA(allKOTsVPA);
      setOrders(allOrders);
    };

    if (db) fetchOrders();
  }, [db]);

  useEffect(() => {
    const fetchOrders = async () => {
      const allOrders = await getAllOrders();
      setOrders(allOrders);
    };

    fetchOrders();
  }, [db]);

  const getAllOrders = async () => {
    if (!db) return [];
    return await db.getAll(IN_PROCESS_ORDERS_STORE_NAME);
  };

  const getHighestBillNoFromCompletedOrders = async () => {
    if (!db) return 0;
    const completedOrders = await db.getAll(COMPLETE_ORDERS_STORE_NAME);
    if (completedOrders.length === 0) return 0;
    return completedOrders.reduce((max, order) => {
      return order.billNo > max ? order.billNo : max;
    }, 0);
  };

  const saveOrder = async (order) => {
    const db = store.dbInstance;
    if (!db) return;
    await db.add(IN_PROCESS_ORDERS_STORE_NAME, order);
    const updatedOrders = await getAllOrders();
    setOrders(updatedOrders);
  };

  const saveCompleteOrder = async (order) => {
    const db = store.dbInstance;
    if (!db) return;
    await db.add(COMPLETE_ORDERS_STORE_NAME, order);
  };

  const updateOrder = async (order) => {
    const db = store.dbInstance;
    if (!db) return;
    await db.put(IN_PROCESS_ORDERS_STORE_NAME, order);
    const updatedOrders = await getAllOrders();
    setOrders(updatedOrders);
  };

  const deleteOrder = async (id) => {
    const db = store.dbInstance;
    if (!db) return;
    await db.delete(IN_PROCESS_ORDERS_STORE_NAME, id);
    const updatedOrders = await getAllOrders();
    setOrders(updatedOrders);
  };

  const orderExists = async (tableVPA) => {
    const db = store.dbInstance;
    if (!db) return false;
    const order = await db.get(IN_PROCESS_ORDERS_STORE_NAME, tableVPA);
    return !!order;
  };

  const getUpdatedBillNo = () => {
    const lastBillNo = Number(store.lastBillNo);
    const updatedBillNo = lastBillNo + 1;
    store.setLastBillNo(updatedBillNo);
    localStorage.setItem("lastBillNo", updatedBillNo);
    return updatedBillNo;
  };

  const processOrder = async ({
    rawOrder,
    formatedOrder,
    tableVPA,
    cancleOrders = [],
    extraOrders = [],
    username = "NA",
    usermobile = "NA",
    orderStatus = "KOTPrint",
    onlineBillNo = "NA",
    replaceItems = "",
  }) => {
    const createNewOrder = async () => {
      const newOrderId = getUpdatedBillNo();
      const newOrder = {
        usermobile: usermobile,
        username: username,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        id: newOrderId,
        billNo: newOrderId,
        tableVPA: tableVPA,
        status: orderStatus,
        backendOrder: {
          items: formatedOrder,
          usermobile: "NA",
          username: "NA",
          tableVPA: tableVPA,
        },
        cancleOrders: [],
        extraOrders: extraOrders,
        onlineBillNo: onlineBillNo,
      };
      const billAmount = rawOrder.reduce((acc, item) => {
        let price = 0;
        if (item?.selectedVariant) {
          price = parseFloat(item.selectedVariant.varientAmount).toFixed(2);
        } else {
          price = parseFloat(item.price).toFixed(2);
        }
        return acc + price * item.qty;
      }, 0);

      newOrder["amount"] = billAmount;
      newOrder["printKOT"] = {
        amount: newOrder.amount,
        billNo: newOrder.billNo,
        onlineBillNo: newOrder.onlineBillNo,
        name: newOrder.username,
        tableVPA: newOrder.tableVPA,
        items: rawOrder.reduce((acc, item) => {
          if (item?.selectedVariant) {
            return [
              ...acc,
              {
                itemId: item.id,
                name: item.name + " - " + item.selectedVariant.varientName,
                price: item.selectedVariant.varientAmount,
                quantity: item.qty,
                status: newOrder.status,
              },
            ];
          }
          return [
            ...acc,
            {
              itemId: item.id,
              name: item.name,
              price: item.price,
              quantity: item.qty,
              status: newOrder.status,
            },
          ];
        }, []),
      };

      // stored in indexedDB
      await saveOrder(newOrder);
      // process to store kot in store
      store.pushPrintedKOTs(newOrder.printKOT);
      store.pushPrintedKOT_VPA({ tableVPA: newOrder.tableVPA });
    };

    const updateExistingOrder = async () => {
      const db = store.dbInstance;
      const existingOrder = await db.get(
        IN_PROCESS_ORDERS_STORE_NAME,
        tableVPA
      );
      if (cancleOrders.length > 0) {
        const cancleAmount = cancleOrders.reduce((acc, item) => {
          // check if it a extra item or not
          if (item?.itemId) {
            // remove from backend order
            existingOrder["backendOrder"]["items"] = existingOrder[
              "backendOrder"
            ]["items"].filter(
              (existingItem) => existingItem.itemId !== item.itemId
            );
          } else {
            // remove from extra orders
            existingOrder["extraOrders"] = existingOrder["extraOrders"].filter(
              (existingItem) => existingItem.name !== item.name
            );
          }
          // remove from printKOT
          existingOrder["printKOT"]["items"] = existingOrder["printKOT"][
            "items"
          ].filter((existingItem) => {
            if (existingItem?.itemId) {
              return existingItem.itemId !== item.itemId;
            } else {
              return existingItem.name !== item.name;
            }
          });

          acc += parseInt(item.price) * parseInt(item.quantity);
          return acc;
        }, 0);
        existingOrder["amount"] -= cancleAmount;
      }

      if (
        existingOrder.onlineBillNo === onlineBillNo &&
        onlineBillNo !== "NA"
      ) {
        let items = existingOrder.printKOT.items;
        items = items.filter((item) => item.status !== replaceItems);
        existingOrder.printKOT.items = items;
        existingOrder.backendOrder.items = items.map((item) => ({
          itemId: item.itemId,
          quantity: item.quantity,
          varientid: item.varientid,
        }));
      }
      existingOrder["updatedAt"] = new Date().toISOString();
      if (
        onlineBillNo === "NA" ||
        existingOrder.onlineBillNo !== onlineBillNo
      ) {
        const newBillAmount = rawOrder.reduce((acc, item) => {
          let price = 0;
          if (item?.selectedVariant) {
            price = parseInt(item.selectedVariant.varientAmount);
          } else {
            price = parseInt(item.price);
          }
          return acc + price * item.qty;
        }, 0);
        existingOrder["amount"] += newBillAmount;
        existingOrder["amount"] += existingOrder?.discount
          ? existingOrder["discount"]
          : 0;
        existingOrder["onlineBillNo"] = onlineBillNo;
      }

      existingOrder["discount"] = 0;

      existingOrder["backendOrder"]["items"] = [
        ...existingOrder["backendOrder"]["items"],
        ...formatedOrder,
      ];
      existingOrder["printKOT"]["amount"] = existingOrder["amount"];
      existingOrder["printKOT"]["items"] = [
        ...existingOrder["printKOT"]["items"],
        ...rawOrder.reduce((acc, item) => {
          if (item?.selectedVariant) {
            return [
              ...acc,
              {
                itemId: item.id,
                name: item.name + " - " + item.selectedVariant.varientName,
                price: item.selectedVariant.varientAmount,
                quantity: item.qty,
                status: orderStatus,
              },
            ];
          }
          return [
            ...acc,
            {
              itemId: item.id,
              name: item.name,
              price: item.price,
              quantity: item.qty,
              status: orderStatus,
            },
          ];
        }, []),
      ];
      existingOrder["cancleOrders"] = [
        ...existingOrder["cancleOrders"],
        ...cancleOrders,
      ];
      existingOrder["extraOrders"] = [
        ...existingOrder["extraOrders"],
        ...extraOrders,
      ];
      existingOrder["status"] = "KOTUpdated";

      await updateOrder(existingOrder);
      store.updatePrintedKOTs(existingOrder.printKOT);
      fireKOTUpdatedEvent(existingOrder.printKOT);
    };

    console.log({ rawOrder, formatedOrder, tableVPA, store });
    return new Promise(async (resolve, reject) => {
      try {
        const isExists = await orderExists(tableVPA);

        if (isExists) {
          console.log("Order exists");
          await updateExistingOrder();
        } else {
          console.log("Order does not exists");
          await createNewOrder();
        }
        resolve(true);
      } catch (error) {
        console.error("Error processing order:", error);
        reject(false);
      }
    });
  };

  const acceptOnlineOrder = async (tableVPA) => {
    const db = store.dbInstance;
    const order = await db.get(IN_PROCESS_ORDERS_STORE_NAME, tableVPA);
    order.printKOT.items.forEach((item) => {
      item.status = "KOTPrint";
    });
    order.status = "KOTPrint";

    await updateOrder(order);
    store.updatePrintedKOTs(order.printKOT);
    fireKOTUpdatedEvent(order.printKOT);
  };

  const rejectOnlineOrder = async (tableVPA) => {
    const db = store.dbInstance;
    const order = await db.get(IN_PROCESS_ORDERS_STORE_NAME, tableVPA);
    const items = order.printKOT.items;
    order.printKOT.items = items.filter((item) => {
      item.status = "KOTPrint";
    });
    order.backendOrder.items = items
      .filter((item) => {
        item.status = "KOTPrint";
      })
      .map((item) => ({
        itemId: item.itemId,
        quantity: item.quantity,
        varientid: item.varientid || undefined,
      }));

    if (order.printKOT.items.length > 0) {
      await updateOrder(order);
      store.updatePrintedKOTs(order.printKOT);
      fireKOTUpdatedEvent(order.printKOT);
    } else {
      await deleteOrder(tableVPA);
      store.removeDigitalOrderVPA(tableVPA);
      store.removePrintedKOT(tableVPA);
      store.removePrintedKOT_VPA(tableVPA);
    }
  };

  const cancelOrder = async (tableVPA) => {
    const db = store.dbInstance;
    if (!db) return;
    try {
      const order = await db.get(IN_PROCESS_ORDERS_STORE_NAME, tableVPA);
      order.status = "BILLCancelled";
      await db.put(COMPLETE_ORDERS_STORE_NAME, order);
      await db.delete(IN_PROCESS_ORDERS_STORE_NAME, tableVPA);
      store.removePrintedKOT_VPA(tableVPA);
      store.removePrintedKOT(tableVPA);
      return true;
    } catch (error) {
      console.error("Error processing order:", error);
      return false;
    }
  };

  const updateOrderStatus = async (tableVPA, status) => {
    const db = store.dbInstance;
    if (!db) return;
    const order = await db.get(IN_PROCESS_ORDERS_STORE_NAME, tableVPA);
    order.status = status;
    await db.put(IN_PROCESS_ORDERS_STORE_NAME, order);
    const updatedOrders = await getAllOrders();
    setOrders(updatedOrders);
  };

  const updateOrderStatusToBillGenerated = async (tableVPA) => {
    await updateOrderStatus(tableVPA, "KOTBillGenerated");
  };

  const generateOrderBill = async ({
    tableVPA,
    waiterid,
    discount,
    totalAmount,
    name,
    mobile,
  }) => {
    const order = await db.get(IN_PROCESS_ORDERS_STORE_NAME, tableVPA);
    order.status = "KOTBillGenerated";
    order.assignedWaiter = waiterid;
    order.discount = discount;
    order.amount = totalAmount;
    order.usermobile=mobile;
    order.username =name;
    order["printKOT"]["amount"] = totalAmount;
    await db.put(IN_PROCESS_ORDERS_STORE_NAME, order);
    const updatedOrders = await getAllOrders();
    setOrders(updatedOrders);
    store.updatePrintedKOTs(order.printKOT);
    fireKOTBillGeneratedEvent(order.printKOT);
  };

  const completeOrderBill = async (order) => {
    return new Promise(async (resolve, reject) => {
      const { tableVPA, methods, paymentname, tips} = order;
      try {
        const existingOrder = await db.get(
          IN_PROCESS_ORDERS_STORE_NAME,
          tableVPA
        );
        existingOrder["status"] = "KOTBillCompleted";
       
        existingOrder["payment"] = {
          methods,
          paymentname,
          tips,
        };
        existingOrder["updatedAt"] = new Date().toISOString();
        existingOrder["amount"] =
          existingOrder["amount"] - order["extraDiscount"];
        existingOrder["extraDiscount"] = parseInt(order["extraDiscount"]);
        existingOrder['tips'] = parseInt(order['tips']);

        store.removePrintedKOT_VPA(tableVPA);
        store.removePrintedKOT(tableVPA);
        await saveCompleteOrder(existingOrder);
        await deleteOrder(tableVPA);
        resolve(true);
      } catch (error) {
        console.error("Error completing order bill:", error);
        reject(false);
      }
    });
  };

  const fireKOTBillGeneratedEvent = (order) => {
    const event = new CustomEvent("KOTBillGenerated", { detail: order });
    window.dispatchEvent(event);
  };

  const fireKOTUpdatedEvent = (order) => {
    const event = new CustomEvent("KOTUpdated", { detail: order });
    window.dispatchEvent(event);
  };

  
  const getAllCompletedOrders = async () => {
    if (!db) return [];
    return await db.getAll(COMPLETE_ORDERS_STORE_NAME);
  };

  const flushAllCompleteOrders = async () => {
    const db = store.dbInstance;
    if (!db) return;
    await pushToOrdersHistory(await getAllCompletedOrders());
    await db.clear(COMPLETE_ORDERS_STORE_NAME);
  };

  const pushToOrdersHistory = async (orders) => {
    const db = store.dbInstance;
    if (!db) return;
    for (const order of orders) {
      await db.add(ORDER_HISTORY_STORE_NAME, order);
    }
  };

  const transformOrderHistory = (local, cloud, noSynced) => {
    const billStatus = (status) => {
      switch (status) {
        case "KOTBillCompleted":
          return "paid";
        case "BILLCancelled":
          return "cancelled";
      }
    };
    const processOrderToHistory = (order) => {
      return {
        id: order.id,
        invoiceNo: order.billNo,
        customerName: order.username,
        waiter: order.assignedWaiter || "Not Available",
        table: `Table ${store.tables[order.tableVPA].tableNo}`,
        state: billStatus(order.status),
        orderDate: new Date(order.createdAt).toDateString(),
        amount: order.amount,
        paymentmethod:
          billStatus(order.status) === "paid"
            ? order.payment.paymentname
            : "NA",
        partialAmoutsplit:
          billStatus(order.status) === "paid" ? order.payment.method || [] : [],
      };
    };

    const localHash = local.reduce((acc, order) => {
      acc[order.billNo] = processOrderToHistory(order);
      return acc;
    }, {});
    const noSyncedHash = noSynced.reduce((acc, order) => {
      acc[order.billNo] = processOrderToHistory(order);
      return acc;
    }, {});

    const cloudHash = cloud.reduce((acc, order) => {
      acc[order.invoiceNo] = order;
      return acc;
    }, {});

    const combineOrders = { ...localHash, ...cloudHash, ...noSyncedHash };
    return Object.values(combineOrders);
  };

  const getOrderHistory = async () => {
    if (!db) return [];
    const cloudOrderHistory = store.orderHistory;
    const localOrderHistory = await db.getAll(ORDER_HISTORY_STORE_NAME);
    const noSyncedOrderHistory = await db.getAll(COMPLETE_ORDERS_STORE_NAME);
    const orderHistory = transformOrderHistory(
      localOrderHistory,
      cloudOrderHistory,
      noSyncedOrderHistory
    );
    return orderHistory;
  };

  const getOnlineOrderBillNo = async (tableVPA) => {
    const db = store.dbInstance;
    if (!db) return false;
    const order = await db.get(IN_PROCESS_ORDERS_STORE_NAME, tableVPA);
    return order.onlineBillNo;
  };

  const plusMinusByBillNo = async (order) => {
    return new Promise(async (resolve, reject) => {
      const { tableVPA, billNo, items } = order;
  
      try {
        // Fetch the existing order from IndexedDB
        const existingOrder = await db.get(IN_PROCESS_ORDERS_STORE_NAME, tableVPA);
  
        if (!existingOrder) {
          console.error("Order not found in IndexedDB.");
          reject("Order not found");
          return;
        }
  
        let updatedAmount = 0;
  
        // Update backendOrder items
        existingOrder.backendOrder.items.forEach((item) => {
          const change = items.find((c) => c.id === item.itemId);
          if (change) {
            item.quantity += change.quantity; // Increment or decrement quantity
            if (item.quantity < 0) item.quantity = 0; // Ensure quantity doesn't go below 0
          }
        });
  
        // Update printKOT items and recalculate total amount
        existingOrder.printKOT.items.forEach((item) => {
          console.log(item);
          
          const change = items.find((c) => c.id === item.itemId);
          if (change) {
            item.quantity += change.quantity; // Increment or decrement quantity
            if (item.quantity < 0) {item.quantity = 0;

            } else{
            
              updatedAmount += parseFloat(item.price) * parseInt(change.quantity); // Recalculate amount
            }
            // Ensure quantity doesn't go below 0
          }
        });
  
        // Update the total amount in the order
        existingOrder.amount =(parseFloat(existingOrder.amount)+parseFloat(updatedAmount)) ;
        existingOrder.printKOT.amount = (parseFloat(existingOrder.printKOT.amount)+parseFloat(updatedAmount));
        existingOrder.status = "KOTUpdated";
  
        // Save the updated order back to IndexedDB
        await db.put(IN_PROCESS_ORDERS_STORE_NAME, existingOrder);
  
        console.log(existingOrder,"Order updated successfully:", updatedAmount);
        fireKOTUpdatedEvent(existingOrder.printKOT);
        store.forceReload();
        resolve(existingOrder);
      } catch (error) {
        console.error("Error updating order in IndexedDB:", error);
        reject(error);
      }
    });
  };

  const handleSwitchTable = async (from, to) => {
    try {
      // Step 1: Fetch the order from IndexedDB
      const table = await db.get(IN_PROCESS_ORDERS_STORE_NAME, from);
  
      if (!table) {
        throw new Error(`Table with VPA ${from} not found.`);
      }
  
      // Step 2: Temporarily store the original data for rollback purposes
      const originalTable = JSON.parse(JSON.stringify(table));
  
      // Step 3: Update all `tableVPA` references
      table.tableVPA = to;
  
      if (table.backendOrder && table.backendOrder.tableVPA) {
        table.backendOrder.tableVPA = to;
      } else {
        throw new Error("backendOrder.tableVPA update failed.");
      }
  
      if (table.printKOT && table.printKOT.tableVPA) {
        table.printKOT.tableVPA = to;
      } else {
        throw new Error("printKOT.tableVPA update failed.");
      }
  
      // Step 4: Validate in-memory updates before committing
      const updatesValid =
        table.tableVPA === to &&
        table.backendOrder.tableVPA === to &&
        table.printKOT.tableVPA === to;
  
      if (!updatesValid) {
        throw new Error("Inconsistent tableVPA updates detected.");
      }
  
      // Step 5: Execute asynchronous updates with validation
      const deleteOldEntry = db.delete(IN_PROCESS_ORDERS_STORE_NAME, from);
      const saveNewEntry = saveOrder(table);
  
      const [deleteResult, saveResult] = await Promise.allSettled([
        deleteOldEntry,
        saveNewEntry,
      ]);
  
      // Check if any operation failed
      if (deleteResult.status !== "fulfilled" || saveResult.status !== "fulfilled") {
        throw new Error("Failed to update IndexedDB.");
      }
  
      // Step 6: Update the store only after successful database updates
      store.removePrintedKOT_VPA(from);
      const all = [...store.printedKOT_VPA, to];
      store.addPrintedKOT_VPA(all);
  
      // Ensure the store update function works correctly
      const storeUpdateResult = await store.switchTableUpdate(from, to);
      if (!storeUpdateResult) {
        throw new Error("Store switchTableUpdate failed.");
      }
  
      console.log(`Table switched successfully from ${from} to ${to}`);
      return true
    } catch (error) {
      console.error("Error switching table:", error.message);
      return false;

      // Rollback: You can optionally restore the original table here
    }
  };
  
  const getTableOrderStatus = async (tableVPA) => {
    try {
      const db = store.dbInstance;
      if (!db) return;
      const order = await db.get(IN_PROCESS_ORDERS_STORE_NAME, tableVPA);
      return order.status;
    } catch (error) {
      console.error("Error fetching table order status:", error);
      return "NA";
    }
  }

  const syncBillNoWithCloudBillNo = async (lastBillNo) => {
    const db = store.dbInstance;
    if (!db) new Error("Database not found");
    const completedOrders = await db.getAll(COMPLETE_ORDERS_STORE_NAME);
    if (completedOrders.length === 0) return [];
    const updatedOrders = completedOrders.map((order) => {
      order.billNo = ++lastBillNo;
      return order;
    });
    await db.clear(COMPLETE_ORDERS_STORE_NAME);
    for (const order of updatedOrders) {
      await db.add(COMPLETE_ORDERS_STORE_NAME, order);
    }
    return updatedOrders;
  }

  const changeOrderPaymentMethod = async ( {billNo, paymentname}) => {
    try {
      const db = store.dbInstance;
      if (!db) return;
      const order = await db.get(COMPLETE_ORDERS_STORE_NAME, billNo);
      order.payment.methods[0].name = paymentname;
      order.payment.paymentname = paymentname;
      await db.put(COMPLETE_ORDERS_STORE_NAME, order);
      const completeOrdersUpdatedEvent = new CustomEvent("COMPLETED_ORDERS_UPDATED");
      window.dispatchEvent(completeOrdersUpdatedEvent);
      return true;
    } catch (error) {
      console.error("Error updating order payment method:", error);
      return false;
    }
  };

  return {
    orders,
    saveOrder,
    updateOrder,
    deleteOrder,
    processOrder,
    updateOrderStatus,
    updateOrderStatusToBillGenerated,
    generateOrderBill,
    completeOrderBill,
    getAllCompletedOrders,
    flushAllCompleteOrders,
    cancelOrder,
    pushToOrdersHistory,
    getOrderHistory,
    acceptOnlineOrder,
    rejectOnlineOrder,
    getOnlineOrderBillNo,
    plusMinusByBillNo,
    handleSwitchTable,
    getTableOrderStatus,
    syncBillNoWithCloudBillNo,
    changeOrderPaymentMethod
  };
};

export default useOrder;
