import { api } from "../../../Environments/Environment.Test";

const authUrl = `${api}/restaurant`;
export function addItemAPI(requestBody) {
  // const navigate = useNavigate();

  return new Promise(async (resolve) => {
    try {
      const token = await localStorage.getItem("authToken");
      const response = await fetch(`${authUrl}/admin/createMenu`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      resolve({ error: error.message });
    }
  });
}

export function updateITem(requestBody, id) {
  // const navigate = useNavigate();

  return new Promise(async (resolve) => {
    try {
      const token = await localStorage.getItem("authToken");
      const response = await fetch(`${authUrl}/admin/createMenu?id=${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      resolve({ error: error.message });
    }
  });
}
// /admin/createMenu

export function getAllItems() {
  // const navigate = useNavigate();

  return new Promise(async (resolve) => {
    try {
      const token = await localStorage.getItem("authToken");
      const response = await fetch(`${authUrl}/admin/createMenu`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      resolve({ error: error.message });
    }
  });
}


export function outOfStock(requestBody) {
  // const navigate = useNavigate();

  return new Promise(async (resolve) => {
    try {
      const token = await localStorage.getItem("authToken");
      const response = await fetch(`${authUrl}/menu/outofstock`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      resolve({ data });
    } catch (error) {
      resolve({ error: error.message });
    }
  });
}
