import React, { useEffect, useState, useCallback } from "react";
import StatCard from "./StatCard";
import OrderList from "./OrderList";
import TopSellingItems from "./TopSelling";
import MonthlySalesChart from "./MonthlySalesChart";
import {
  customdatewise,
  daywise,
  monthwise,
  yearwise,
} from "./Services/ReportServices";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useOrder from "../../hooks/useOrder";
import ItemWiseSell from "../AcceptOrder/ChartsReport/ItemWiseSell";

const Reports = () => {
  const [day, setDay] = useState([]);
  const [month, setMonth] = useState([]);
  const [year, setYear] = useState([]);
  const [customData, setCustomData] = useState([]);
  const [listofBill, setListOfBill] = useState([]);
  const [bgcolorday] = useState([
    "bg-red-500",
    "bg-orange-500",
    "bg-green-500",
    "bg-purple-500",
    'bg-yellow-500',
  ]);
  const [bgforweek] = useState([
    "bg-blue-500",
    "bg-yellow-500",
    "bg-orange-500",
    "bg-red-500",
  ]);
  const [bgforyear] = useState([
    "bg-blue-500",
    "bg-yellow-500",
    "bg-orange-500",
    "bg-red-500",
  ]);

  const orders = [
    {
      name: "Walkin",
      phone: "8801717426371",
      orderNo: "0149",
      tableNo: "Table-3",
      time: "07:34:24",
    },
    // Add more orders as needed
  ];
  const { getAllCompletedOrders, flushAllCompleteOrders } = useOrder();
  const [activeTab, setActiveTab] = useState("today");
  const [startDate, setStartDate] = useState(new Date());
  const [localAmount, setLocalAmount] = useState({
    cash: "0",
    online: "0",
    card: "0",
    pending: "0",
  });
  const [endDate, setEndDate] = useState(new Date());
  const [hideOtherBoxes, setHideOtherBoxes] = useState(false);

  // Fetch report data
  const fetchReportData = useCallback(async () => {
    const dayReport = await daywise();
    const orders = await getAllCompletedOrders();

    let cash = 0;
    let online = 0;
    let card = 0;
    let pending = 0;

    if (dayReport.data?.event) {
      const allLocaltbill = [];
      console.log(orders, ".....orders");

      // Accumulate amounts for each order's payment methods
      orders.forEach((each) => {
        each.payment.methods.forEach((ele) => {
          console.log(ele, "...");

          // Ensure non-zero amount and use each.amount if ele.amount is empty
          const amount =
            ele.amount === "" || ele.amount == null
              ? each.amount
              : parseFloat(ele.amount);
          if (amount !== 0) {
            allLocaltbill.push({
              billNo: each.billNo + "",
              name: ele.name,
              amount: amount,
            });
          }

          // Add to appropriate totals
          switch (ele.name) {
            case "cash":
              cash += amount;
              break;
            case "online":
              online += amount;
              break;
            case "card":
              card += amount;
              break;
            case "pending":
              pending += amount;
              break;
            default:
              break;
          }
        });
      });

      // Update the local state for amounts
      setLocalAmount({
        cash: cash,
        online: online,
        card: card,
        pending: pending,
      });

      console.log(dayReport.data.bills, ".....printed ", allLocaltbill);
      setListOfBill([...allLocaltbill, ...dayReport.data.bills]);

      console.log(dayReport.data.data, "......payment ");

      const allAmount = [];

      // Ensure all payment methods are included
      const paymentTypes = ["cash", "online", "card", "pending", 'tips'];

      // Add the values from dayReport.data.data if present
      paymentTypes.forEach((paymentType) => {
        const dayReportAmount =
          dayReport.data.data?.find((ele) => ele.name === paymentType)
            ?.totalAmount || 0;

        // For each payment type, add the value from orders (e.g., `cash`, `online`, etc.)
        switch (paymentType) {
          case "cash":
            allAmount.push({
              name: "cash",
              totalAmount: parseFloat(dayReportAmount) + cash,
            });
            break;
          case "online":
            allAmount.push({
              name: "online",
              totalAmount: parseFloat(dayReportAmount) + online,
            });
            break;
          case "card":
            allAmount.push({
              name: "card",
              totalAmount: parseFloat(dayReportAmount) + card,
            });
            break;
          case "pending":
            allAmount.push({
              name: "pending",
              totalAmount: parseFloat(dayReportAmount) + pending,
            });
            break;
          default:
            break;
        }
      });

      console.log(allAmount, ".... ");
      allAmount.push({
        name: "tips",
        totalAmount: dayReport.data.totalTips
      });
      setDay(allAmount);
    } else {
    }
    const monthReport = await monthwise();
    if (monthReport.data?.event) {
      setMonth(monthReport.data.data);
    }
    const yearReport = await yearwise();
    if (yearReport.data?.event) {
      setYear(yearReport.data.data);
    }
  }, []);

  useEffect(() => {
    fetchReportData();
  }, [fetchReportData]);

  // Handle filtering based on custom dates and log the selected time and date
  const handleFilter = async () => {
    console.log("Selected Start Date and Time:", startDate);
    console.log("Selected End Date and Time:", endDate);

    setHideOtherBoxes(true);
    const result = await customdatewise({ startDate, endDate });
    if (result.data?.event) {
      setCustomData(result.data.data);
      setActiveTab("custom");
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold text-center my-6">
        <strong>6 AM to 5:59 AM Reporting Cycle</strong>
      </h1>

      <main className="px-6 py-6">
        {/* Date Filter Section */}
        <div className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">
            Custom Date and Time Filter
          </h2>
          <div className="flex space-x-4">
            <div>
              <label className="block">From:</label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showTimeSelect
                dateFormat="Pp" // Display date and time
                className="p-2 border rounded"
              />
            </div>
            <div>
              <label className="block">To:</label>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                showTimeSelect
                dateFormat="Pp" // Display date and time
                className="p-2 border rounded"
              />
            </div>
            <button
              onClick={handleFilter}
              className="bg-blue-500 text-white px-4 mt-6 rounded"
            >
              Filter
            </button>
          </div>
        </div>

        {/* Tabs */}
        {!hideOtherBoxes && (
          <div className="flex space-x-4 mb-6">
            <button
              className={`px-4 py-2 ${
                activeTab === "today" ? "bg-blue-500 text-white" : "bg-gray-200"
              }`}
              onClick={() => handleTabClick("today")}
            >
              Today
            </button>
            <button
              className={`px-4 py-2 ${
                activeTab === "weekly"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200"
              }`}
              onClick={() => handleTabClick("weekly")}
            >
              Weekly
            </button>
            <button
              className={`px-4 py-2 ${
                activeTab === "yearly"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200"
              }`}
              onClick={() => handleTabClick("yearly")}
            >
              Yearly
            </button>
          </div>
        )}

        <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-6 gap-6 mb-6">
          {activeTab === "today" &&
            day.map((ele, ind) => (
              <StatCard
                key={ind}
                color={bgcolorday[ind]}
                value={ele.totalAmount}
                label={`Today's ${ele.name}`}
              />
            ))}
          {activeTab === "weekly" &&
            month.map((ele, ind) => (
              <StatCard
                key={ind}
                color={bgforweek[ind]}
                value={ele.totalAmount}
                label={`Week's ${ele.name}`}
              />
            ))}
          {activeTab === "yearly" &&
            year.map((ele, ind) => (
              <StatCard
                key={ind}
                color={bgforyear[ind]}
                value={ele.totalAmount}
                label={`Year's ${ele.name}`}
              />
            ))}
          {activeTab === "custom" &&
            customData.map((ele, ind) => (
              <StatCard
                key={ind}
                color={bgforyear[ind]}
                value={ele.totalAmount}
                label={`Custom's ${ele.name}`}
              />
            ))}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <TopSellingItems name="card" list={listofBill} />
          <TopSellingItems name="online" list={listofBill} />
          <TopSellingItems name="cash" list={listofBill} />
        </div>
        <div className="p-6 bg-gray-100 min-h-screen">
          <h1 className="text-2xl font-bold mb-4 text-center">
            Items Sales Dashboard
          </h1>
          <ItemWiseSell />
        </div>

        {/* <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-4 text-center">Categories Sales Dashboard</h1>
      <ItemWiseSell />
    </div> */}

        {/* <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 blur-sm">
          <OrderList title="Latest Order" orders={orders} />
          <OrderList title="Pending Order" orders={orders} />
          <OrderList title="Latest Parcel Order" orders={orders} />
        </div>

        <div className="p-8 bg-gray-100 min-h-screen blur-sm">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <TopSellingItems />
            <MonthlySalesChart />
          </div>
        </div> */}
      </main>
    </div>
  );
};

export default Reports;
